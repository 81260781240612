/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
// Utils
import styles from './UploadFile.module.css';
import cn from '../../../utils/cn';

function UploadFile({
  id, name, label, fileName, onChange, required, ...props
}) {
  const { t } = useTranslation();

  return (
    <div>
      <p className={cn([styles.label, 'visually-hidden'])}>
        {label}
      </p>
      <div className={styles.field}>
        <label className={styles.fakeInput} htmlFor={id} title={t('global.select_file')}>
          <FontAwesomeIcon icon={faUpload} />
        </label>
        <input
          className={styles.input}
          id={id}
          type="file"
          name={name}
          onChange={onChange}
          required={required}
          {...props}
        />
        <div className={styles.value}>
          {fileName || <span className={styles.disabled}>{t('global.no_file_selected')}</span>}
        </div>
        {fileName ? (
          <span className={styles.picto}>
            <FontAwesomeIcon icon={faCircleCheck} />
          </span>
        ) : null}
      </div>

    </div>
  );
}

UploadFile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fileName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

UploadFile.defaultProps = {
  fileName: '',
  label: '',
  required: false,
};

export default UploadFile;
