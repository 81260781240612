/* eslint-disable react/prop-types */
import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
// Components
import Layout from '../../components/template/Layout';
import Search from '../../components/molecules/Search/Search';
import Table from '../../components/molecules/Table/Table';
import Pagination from '../../components/molecules/Pagination';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';
// Services
import { getReportsList } from '../../services/reports';
// Styles
import styles from './FillingReports.module.css';
// Utils
import { formatDateToUser } from '../../utils/dates';

function FillingReports() {
  const { t } = useTranslation();
  const [context] = useAppContext();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [search, setSearch] = useState('');

  // API Calls
  const getReportsListQuery = useQuery(['reports', page, search], () => getReportsList({
    bookletId: context.choiceBooklet,
    establishmentId: context.choiceEstablishment.id,
    page,
    search,
  }), {
    cacheTime: 0,
  });

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  useEffect(() => {
    // sorry, for this hacky function, but it exist a little defer
    // between the feedback of back after post and the real refresh of list
    // and as back dev say it's not from DB then..
    setTimeout(() => getReportsListQuery.refetch(), 1500);
  }, []);

  const isDateBeforeToday = (dateToCheck) => dateToCheck && new Date(dateToCheck) < new Date();

  const columns = useMemo(
    () => [
      {
        Header: `${t('reports.building')}`,
        accessor: 'buildingName',
        Cell: ({ row: { original: { buildingName, reportDate } } }) => (
          <div className={`${isDateBeforeToday(reportDate) ? 'tag--error' : ''}`}>
            {buildingName}
          </div>
        ),
      },
      {
        Header: `${t('reports.name')}`,
        accessor: 'title',
        Cell: ({ row: { original: { title, reportDate } } }) => (
          <div className={`${isDateBeforeToday(reportDate) ? 'tag--error' : ''}`}>
            {title}
          </div>
        ),
      },
      {
        Header: `${t('reports.date')}`,
        accessor: 'reportDate',
        Cell: ({ row: { original: { reportDate } } }) => (
          <div className={`${isDateBeforeToday(reportDate) ? 'tag--error' : ''}`}>
            {formatDateToUser(reportDate)}
          </div>
        ),
      },

      {
        Header: ' ',
        Cell: ({ row: { original: { id, societyInChargeId, societyInChargeName } } }) => (
          <div className="actions">
            <Link
              className="action"
              to="/rapports/deposit"
              title={t('reports.deposit')}
              state={{
                interventionId: id,
                societyInChargeId,
                societyInChargeName,
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Link>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Layout
      title={t('reports.title')}
      layout="table"
      queryError={getReportsListQuery.error}
    >
      <header>
        <div className="row">
          <h1 className="title">{t('reports.title')}</h1>
        </div>
        <div className="row">
          <Search className="mb-20" onChange={handleSearch} />
        </div>
      </header>
      <div className={styles.table}>
        <Table
          columns={columns}
          isLoading={getReportsListQuery.isLoading}
          data={getReportsListQuery?.data?.data?.reports || []}
          hasSort
          pagination={(
            <Pagination
              previousLabel={(
                <FontAwesomeIcon icon={faChevronLeft} />
                )}
              nextLabel={(
                <FontAwesomeIcon icon={faChevronRight} />
                )}
              onPageChange={onPageChange}
              totalCount={getReportsListQuery?.data?.data?.total || 0}
              currentPage={page}
              pageSize={10}
              numberElementsDisplayed={getReportsListQuery?.data?.data?.reports?.length || 0}
            />
          )}
        />
      </div>
    </Layout>
  );
}

export default FillingReports;
