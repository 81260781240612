/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
// Utils
import styles from './Toggle.module.css';

function Toggle({
  id, label, checked, value, onChange,
}) {
  return (
    <div className={styles.toggle}>
      <div className={styles.toggleInput}>
        <input
          className={styles.input}
          id={id}
          type="checkbox"
          checked={checked}
          value={value}
          onChange={onChange}
        />
        <label className={styles.label} htmlFor={id}>
          <span className={styles.inner} />
        </label>
      </div>
      <div className={styles.fakeLabel}>
        {label}
      </div>
    </div>
  );
}

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  checked: false,
};

export default Toggle;
