/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faSignOut, faUser, faUserSecret, faBars, faXmark,
} from '@fortawesome/free-solid-svg-icons';
// Hooks
import useAppContext from '../../store/useAppContext';
// Components
import Navigation from '../molecules/Navigation/Navigation';
import QueryError from '../molecules/QueryError/QueryError';
// Utils
import userPicture from '../../assets/img/user.jpg';
import styles from './Layout.module.css';
import cn from '../../utils/cn';
import Button from '../atoms/Button/Button';
import { USERS_ROLES } from '../../utils/constant';
import Picto from '../atoms/Picto/Picto';

function Layout({
  title, description, layout, queryError, children,
}) {
  // States
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  // Hooks
  const { t } = useTranslation();
  const [context, dispatch, currentUser] = useAppContext();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch({ type: 'CLEAR_CONTEXT' });
    navigate('/');
  };

  const handleGoBackAccount = () => {
    dispatch({ type: 'CLEAR_USER_IMPERSONATED' });
    navigate('/dashboard');
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 60rem)');
    const resize = () => {
      if (mediaQuery.matches) {
        setMenuCollapsed(true);
      } else {
        setMenuCollapsed(false);
      }
    };
    resize();
    mediaQuery.addEventListener('change', resize);
    return () => mediaQuery.removeEventListener('change', resize);
  }, []);

  const isAdminOrSuperAdmin = context.user.role === USERS_ROLES.SUPER_ADMIN || context.user.role === USERS_ROLES.ADMIN;
  const nameEstablishment = context?.choiceEstablishment?.name;

  return (
    <>
      <Helmet>
        <title>
          {title}
          {' '}
          -
          {' '}
          {t('global.site_name')}
        </title>
        <meta name="description" content={description} />
      </Helmet>
      <div className={styles.layout}>
        <aside className={cn([styles.aside, menuCollapsed ? styles.asideCollapsed : '', menuVisible ? styles.asideVisible : ''])}>
          <Navigation isCollapsed={menuCollapsed} />
          <button type="button" className={styles.menuToggle} onClick={() => setMenuCollapsed(!menuCollapsed)}>
            <FontAwesomeIcon icon={menuCollapsed ? faChevronRight : faChevronLeft} />
          </button>
        </aside>
        <header className={cn([styles.header, !isAdminOrSuperAdmin && (context.choiceBooklet === 2 ? styles.headerSecurite : ''),
          !isAdminOrSuperAdmin && (context.choiceBooklet === 1 ? styles.headerSanitaire : '')])}
        >
          <button type="button" className={cn([styles.menuBurger, menuVisible ? styles.menuBurgerVisible : ''])} onClick={() => setMenuVisible(!menuVisible)}>
            <FontAwesomeIcon icon={menuVisible ? faXmark : faBars} />
          </button>
          { !!nameEstablishment
          && (
            <h2 className={cn([styles.nameEstablishment])}>
              <Picto className={styles.picto} name="etablissementsBlancSimple" width="40" height="40" />
              { nameEstablishment }
            </h2>
          )}
          <div role="button" className={styles.profile} onClick={() => setDropdown(!dropdown)} aria-hidden="true">
            <div className={styles.userName}>
              {`${currentUser.firstName} ${currentUser.lastName}`}
            </div>
            <img className={styles.userPicture} src={userPicture} alt="" width="32" height="32" />
          </div>
          <div className={cn([styles.dropdown, !dropdown ? 'hidden' : '', 'shadow-lg'])}>
            <Link className={styles.dropdownLink} to="/utilisateurs/editer">
              <FontAwesomeIcon icon={faUser} />
              <span className={styles.dropdownLabel}>Mon Profil</span>
            </Link>
            <div className={styles.divider} />
            <button type="button" className={styles.dropdownLink} onClick={handleSignOut}>
              <FontAwesomeIcon icon={faSignOut} />
              <span className={styles.dropdownLabel}>Déconnexion</span>
            </button>
          </div>
          {context.userImpersonated ? (
            <Button
              type="button"
              className={styles.switchUser}
              label={<FontAwesomeIcon icon={faUserSecret} />}
              title={t('global.go_back_account')}
              onClick={handleGoBackAccount}
            />
          ) : null}
        </header>
        <main className={cn([styles.main, layout === 'table' ? styles.table : ''])}>
          <QueryError error={queryError} />
          {children}
        </main>
      </div>
    </>
  );
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  layout: PropTypes.oneOf(['table', '']),
  queryError: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
      }),
    }),
  }),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

Layout.defaultProps = {
  layout: '',
  queryError: null,
  description: 'Ecarnet - Expertise et accompagnement',
};

export default Layout;
