/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// Components
import Picto from '../../atoms/Picto/Picto';
import Divider from '../../atoms/Divider/Divider';
import List from '../List/List';
import ListItem from '../../atoms/ListItem/ListItem';
// Hooks
import useAppContext from '../../../store/useAppContext';
// Utils
import logo from '../../../assets/img/logos/e-carnet-default-logo.png';
import logoMini from '../../../assets/img/logos/e-carnet-default-icone.png';
import logoSanitaire from '../../../assets/img/logos/e-carnet-sanitaire-logo.png';
import logoSanitaireMini from '../../../assets/img/logos/e-carnet-sanitaire-icone.png';
import logoSecurité from '../../../assets/img/logos/e-carnet-securite-logo.png';
import logoSecuritéMini from '../../../assets/img/logos/e-carnet-securite-icone.png';

import styles from './Navigation.module.css';
import cn from '../../../utils/cn';
import {
  CONFIGURATION, STRUCTURES, USERS, USERS_ROLES, ESTABLISHMENTS, INTERVENTIONS, INPUTS,
} from '../../../utils/constant';

function Navigation({ isCollapsed }) {
  // States
  const [accordionConfigurationClosed, setAccordionConfigurationClosed] = useState(false);
  const [accordionStructuresClosed, setAccordionStructuresClosed] = useState(false);
  const [accordionInterventionsClosed, setAccordionInterventionsClosed] = useState(false);
  const [accordionUsersClosed, setAccordionUsersClosed] = useState(false);
  // Hooks
  const { t } = useTranslation();
  const [context] = useAppContext();

  const isBookletChoosed = !!context?.choiceBooklet;
  const isSecurityBooklet = context.choiceBooklet === 2;
  const isEstablishmentChoosed = !!context?.choiceEstablishment;
  const isAdminOrSuperAdmin = context.user.role === USERS_ROLES.SUPER_ADMIN || context.user.role === USERS_ROLES.ADMIN;
  const isUser = context.user.role === USERS_ROLES.USER;

  const configuration = [
    {
      link: CONFIGURATION.EQUIPMENTS_LINK,
      picto: CONFIGURATION.EQUIPMENTS_PICTO,
      label: 'navigation.equipment',
    },
    {
      link: CONFIGURATION.EQUIPMENTS_FAMILIES_LINK,
      picto: CONFIGURATION.EQUIPMENTS_FAMILIES_PICTO,
      label: 'navigation.equipment_family',
    },
    {
      link: CONFIGURATION.CONTROLS_LINK,
      picto: CONFIGURATION.CONTROLS_PICTO,
      label: 'navigation.control',
    },
    {
      link: CONFIGURATION.CONTROLS_TYPES_LINK,
      picto: CONFIGURATION.CONTROLS_TYPES_PICTO,
      label: 'navigation.control_types',
    },
    {
      link: CONFIGURATION.BUILDINGS_TEMPLATES_LINK,
      picto: CONFIGURATION.BUILDINGS_TEMPLATES_PICTO,
      label: 'navigation.buildings_templates',
    },
  ];

  const structures = [
    {
      link: STRUCTURES.STRUCTURES_LINK,
      picto: STRUCTURES.STRUCTURES_PICTO,
      label: 'navigation.structures',
    },
    {
      link: ESTABLISHMENTS.ESTABLISHMENTS_LINK,
      picto: ESTABLISHMENTS.ESTABLISHMENTS_PICTO,
      label: 'navigation.establishments',
    },
  ];

  const users = [
    {
      link: USERS.USERS_LINK,
      picto: USERS.USERS_PICTO,
      label: 'navigation.users',
    },
  ];

  const interventions = [
    {
      link: INTERVENTIONS.TRAININGS_LINK,
      picto: INTERVENTIONS.TRAININGS_PICTO,
      label: 'navigation.trainings',
    },
    {
      link: INPUTS.INPUTS_LINK,
      picto: INPUTS.INPUTS_PICTO,
      label: 'navigation.inputs',
    },
    {
      link: INTERVENTIONS.INTERVENTIONS_LINK,
      picto: INTERVENTIONS.INTERVENTIONS_PICTO,
      label: 'navigation.interventions',
    },
  ];

  const homeLink = () => {
    if (!isBookletChoosed && !isAdminOrSuperAdmin) return '/choix-carnet';
    if (!isEstablishmentChoosed && !isAdminOrSuperAdmin) return '/choix-etablissement';
    return '/';
  };

  const headerLogo = () => {
    if (isBookletChoosed) {
      if (!isCollapsed) return isSecurityBooklet ? logoSecurité : logoSanitaire;
      return isSecurityBooklet ? logoSecuritéMini : logoSanitaireMini;
    }
    if (!isCollapsed) return logo;
    return logoMini;
  };

  return (
    <nav className={styles.navigation}>
      <List>
        <ListItem className={styles.logoItem}>
          <Link className={styles.link} to={homeLink()}>
            <img
              className={styles.logo}
              src={headerLogo()}
              alt={t('global.site_name')}
              width={!isCollapsed ? '151' : '48'}
              height="48"
            />
          </Link>
        </ListItem>
        {(!isAdminOrSuperAdmin) && (
          <>
            <ListItem>
              <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/choix-carnet">
                <Picto className={styles.picto} name="ecarnetsmallwhite" />
                {!isCollapsed && (<span className={styles.linkLabel}>{t('navigation.choice_booklet')}</span>)}
              </Link>
            </ListItem>
            {isBookletChoosed && (
              <ListItem>
                <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/choix-etablissement">
                  <Picto className={styles.picto} name="etablissementsBlanc" />
                  {!isCollapsed && (<span className={styles.linkLabel}>{t('navigation.choice_establishment')}</span>)}
                </Link>
              </ListItem>
            )}
            <Divider />
          </>
        )}
        {((isBookletChoosed && isEstablishmentChoosed) || isAdminOrSuperAdmin)
        && (
        <>
          <ListItem>
            <Link className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])} to="/dashboard">
              <Picto className={styles.picto} name="home" />
              {!isCollapsed ? (
                <span className={styles.linkLabel}>{t('navigation.dashboard')}</span>
              ) : null}
            </Link>
          </ListItem>
          <Divider />
          {(isAdminOrSuperAdmin) && (
            <>
              <ListItem>
                <div
                  role="button"
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  onClick={() => { setAccordionConfigurationClosed(!accordionConfigurationClosed); }}
                  aria-hidden="true"
                >
                  <Picto className={styles.picto} name="configuration" />
                  {!isCollapsed ? (
                    <>
                      <span className={styles.linkLabel}>{t('navigation.configuration')}</span>
                      <FontAwesomeIcon
                        className={styles.linkPicto}
                        icon={accordionConfigurationClosed ? faChevronDown : faChevronRight}
                      />
                    </>
                  ) : null}
                </div>
                {accordionConfigurationClosed ? (
                  <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
                    {configuration.map((item) => (
                      <ListItem key={item.id} className={styles.subItem}>
                        <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                          <Picto className={styles.picto} name={item.picto} />
                          <span className={styles.linkLabel}>{t(item.label)}</span>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                ) : null}
              </ListItem>
              <ListItem>
                <div
                  role="button"
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  onClick={() => { setAccordionStructuresClosed(!accordionStructuresClosed); }}
                  aria-hidden="true"
                >
                  <Picto className={styles.picto} name="contratsBlanc" />
                  {!isCollapsed ? (
                    <>
                      <span className={styles.linkLabel}>{t('navigation.structures')}</span>
                      <FontAwesomeIcon
                        className={styles.linkPicto}
                        icon={accordionStructuresClosed ? faChevronDown : faChevronRight}
                      />
                    </>
                  ) : null}
                </div>
                {accordionStructuresClosed ? (
                  <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
                    {structures.map((item) => (
                      <ListItem key={item.id} className={styles.subItem}>
                        <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                          <Picto className={styles.picto} name={item.picto} />
                          <span className={styles.linkLabel}>{t(item.label)}</span>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                ) : null}
              </ListItem>
              <ListItem>
                <div
                  role="button"
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  onClick={() => { setAccordionUsersClosed(!accordionUsersClosed); }}
                  aria-hidden="true"
                >
                  <Picto className={styles.picto} name="usersWhite" />
                  {!isCollapsed ? (
                    <>
                      <span className={styles.linkLabel}>{t('navigation.users')}</span>
                      <FontAwesomeIcon
                        className={styles.linkPicto}
                        icon={accordionUsersClosed ? faChevronDown : faChevronRight}
                      />
                    </>
                  ) : null}
                </div>
                {accordionUsersClosed ? (
                  <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
                    {users.map((item) => (
                      <ListItem key={item.id} className={styles.subItem}>
                        <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                          <Picto className={styles.picto} name={item.picto} />
                          <span className={styles.linkLabel}>{t(item.label)}</span>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                ) : null}
              </ListItem>
              <Divider />
            </>
          )}
          {(isUser) && (
            <>
              <ListItem>
                <div
                  role="button"
                  className={cn([styles.link, isCollapsed ? styles.linkCenter : ''])}
                  onClick={() => { setAccordionInterventionsClosed(!accordionInterventionsClosed); }}
                  aria-hidden="true"
                >
                  <Picto className={styles.picto} name="interventionsWhite" />
                  {!isCollapsed ? (
                    <>
                      <span className={styles.linkLabel}>{t('navigation.interventions')}</span>
                      <FontAwesomeIcon
                        className={styles.linkPicto}
                        icon={accordionInterventionsClosed ? faChevronDown : faChevronRight}
                      />
                    </>
                  ) : null}
                </div>
                {accordionInterventionsClosed ? (
                  <List className={cn([styles.subList, isCollapsed ? styles.dropdown : ''])}>
                    {interventions.map((item) => {
                      if (!isSecurityBooklet && item.label === 'navigation.trainings') return null;
                      return (
                        <ListItem key={item.id} className={styles.subItem}>
                          <Link className={cn([styles.link, styles.subLink])} to={item.link}>
                            <Picto className={styles.picto} name={item.picto} />
                            <span className={styles.linkLabel}>{t(item.label)}</span>
                          </Link>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : null}
              </ListItem>
              <Divider />
            </>
          )}
        </>
        )}
      </List>
    </nav>
  );
}

export default Navigation;
