import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// components
import Layout from '../../components/template/Layout';
import logoSanitaire from '../../assets/img/logos/e-carnet-sanitaire-logo.png';
import logoSecurite from '../../assets/img/logos/e-carnet-securite-logo.png';
import bckgSecurite from '../../assets/img/background_securite.jpg';
import bckgSanitaire from '../../assets/img/background_sanitaire.jpg';

// hooks
import useAppContext from '../../store/useAppContext';

// style
import styles from './ChoiceBooklet.module.css';

// util
import cn from '../../utils/cn';

export const choicesBooklet = [
  {
    id: 2,
    value: 2,
    key: 'securite',
    label: 'Sécurité',
    title: 'add_building_template.booklet_security',
    backgroundImg: bckgSecurite,
    logoChoice: logoSecurite,
  },
  {
    id: 1,
    value: 1,
    key: 'sanitaire',
    label: 'Sanitaire',
    title: 'add_building_template.booklet_sanitary',
    backgroundImg: bckgSanitaire,
    logoChoice: logoSanitaire,
  },
];

function ChoiceBooklet() {
  const { t } = useTranslation();
  const [, dispatch] = useAppContext();

  const handleChoiceBooklet = (id) => dispatch({ type: 'CHOICE_BOOKLET', payload: id });
  return (
    <Layout
      title={t('choice_booklet.title_page')}
    >
      <header className={cn(['header', styles.headerChoice, 'shadow-md'])}>
        <h1 className="title">
          {t('choice_booklet.title_page')}
        </h1>
      </header>
      <section className={styles.sectionChoice}>
        {choicesBooklet.map((choice) => (
          <Link
            onClick={() => handleChoiceBooklet(choice.id)}
            className={cn(['shadow-md', styles.choiceWrapper])}
            key={choice.key}
            to="/choix-etablissement"
          >
            <div className={styles.backgroundBooklet} style={{ backgroundImage: `url(${choice.backgroundImg})` }} />
            <div className={styles.choiceBooklet}>
              <img src={choice.logoChoice} alt="" />
            </div>
          </Link>
        ))}
      </section>
    </Layout>
  );
}

export default ChoiceBooklet;
