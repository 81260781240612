/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
// Utils
import { noop } from 'lodash';
import styles from './Input.module.css';
import cn from '../../../utils/cn';

function Input({
  id, type, name, label, labelHidden, placeholder, value, onChange, onBlur, disabled, required, step, ...props
}) {
  return (
    <>
      <label className={cn([styles.label, labelHidden ? 'visually-hidden' : ''])} htmlFor={id}>
        {label}
        {' '}
        {required ? '*' : ''}
      </label>
      <input
        className={styles.input}
        id={id}
        type={type}
        name={name}
        placeholder={placeholder || null}
        value={value}
        onChange={onChange}
        autoComplete="off"
        onBlur={onBlur && onBlur}
        disabled={disabled || ''}
        required={required || null}
        step={step}
        {...props}
      />
    </>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
};

function emptyFunc() {}
Input.defaultProps = {
  onChange: noop,
  labelHidden: false,
  placeholder: undefined,
  value: undefined,
  onBlur: emptyFunc,
  step: null,
  required: false,
};

export default Input;
