import React from 'react';
import PropTypes from 'prop-types';

// styles
import styles from './EstablishmentCardChoice.module.css';

// utils & misc
import cn from '../../../../utils/cn';

function EstablishmentCardChoice({ establishment, onClick }) {
  return (
    <button
      type="button"
      onClick={() => onClick && onClick(establishment)}
      className={cn([styles.card, 'shadow-sm'])}
    >
      <h3 className={cn([styles.headerCard, 'title'])}>
        {establishment.name}
      </h3>
      <div className={styles.bodyCard} />
    </button>
  );
}

EstablishmentCardChoice.propTypes = {
  establishment: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

function emptyFunc() {}
EstablishmentCardChoice.defaultProps = {
  onClick: emptyFunc,
};

export default EstablishmentCardChoice;
