import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

// components
import Layout from '../../components/template/Layout';
import EstablishmentCardChoice from '../../components/molecules/List/EstablishmentCardChoice/EstablishmentCardChoice';
import Search from '../../components/molecules/Search/Search';

// hooks
import useAppContext from '../../store/useAppContext';

// infrastructures
import { getEstablishmentsList } from '../../services/structures';

// style
import styles from './ChoiceEstablishment.module.css';

// util
import cn from '../../utils/cn';
import Picto from '../../components/atoms/Picto/Picto';

function ChoiceEstablishment() {
  const [search, setSearch] = useState('');
  const [establishmentsFiltered, setEstablishmentsFiltered] = useState([]);

  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();
  const navigate = useNavigate();

  const getEstablishmentsListQuery = useQuery(['establishments'], () => getEstablishmentsList({
    bookletId: context.choiceBooklet,
  }));

  const handleChoiceEstablishment = (establishment) => {
    dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: establishment });
    navigate('/dashboard');
  };

  useEffect(() => {
    if (getEstablishmentsListQuery.isSuccess && search === '') {
      setEstablishmentsFiltered(getEstablishmentsListQuery.data.data.establishments);
    }
  }, [getEstablishmentsListQuery]);

  const handleSearch = (e) => {
    const establishmentsFilteredBySearch = getEstablishmentsListQuery.data.data.establishments.filter(
      (establishment) => establishment.name
        .toString()
        .toLowerCase()
        .indexOf(e.target.value.toLowerCase()) >= 0,
    );
    setEstablishmentsFiltered(establishmentsFilteredBySearch);
    setSearch(e.target.value);
  };

  return (
    <Layout
      title={t('choice_establishment.title_page')}
      queryError={
        getEstablishmentsListQuery.error
      }
    >
      <header className={
        cn(['header',
          styles.headerChoice,
          'shadow-md',
          context.choiceBooklet === 2 ? styles.security : styles.sanitary])
}
      >
        <h1 className="title">
          <Picto name="etablissementsRoundedBlue" />
          {' '}
          {t('choice_establishment.title_page')}
          {' '}
          <Picto name="etablissementsRoundedBlue" />
        </h1>
      </header>
      {
        getEstablishmentsListQuery.isLoading ? (
          <div className="loader" />
        )
          : (
            <section className={styles.sectionChoice}>
              <div className={styles.searchRow}>
                <Search
                  className="mb-20"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
              <div className={styles.sectionChoiceList}>
                {establishmentsFiltered.map((establishment) => (
                  <EstablishmentCardChoice
                    onClick={handleChoiceEstablishment}
                    establishment={establishment}
                    key={establishment.id}
                  />
                )) || ''}
              </div>
            </section>
          )
      }

    </Layout>
  );
}

export default ChoiceEstablishment;
