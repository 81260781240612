/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faTrashCan, faChevronLeft, faChevronRight, faAddressCard,
} from '@fortawesome/free-solid-svg-icons';

// Components
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';

// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';

// services
import { getSocietiesList } from '../../services/societies';
import { deleteIntervention, getInterventionsList } from '../../services/interventions';
import { getUsersList } from '../../services/users';

// utils & misc
import { formatDateToUser } from '../../utils/dates';
import { isInputIntervention } from '../../utils/interventions';

// styles
import styles from './Interventions.module.css';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Interventions() {
  const [openModal, setOpenModal] = useState(false);
  const [trainingToDelete, setInterventionToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');

  // Hooks
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [context] = useAppContext();

  const getInterventionsListQuery = useQuery(['interventions', page, search], () => getInterventionsList({
    page,
    establishmentId: context?.choiceEstablishment?.id,
    bookletId: context?.choiceBooklet,
    search,
  }));

  const getSocietiesListQuery = useQuery(['societiesList'], () => getSocietiesList({
    mine: true,
    structureId: context.choiceEstablishment.id,
    bookletId: context.choiceBooklet,
  }));
  const findSociety = (societyId) => getSocietiesListQuery?.data?.data?.societies
    .find((society) => society.id === societyId);

  const getUsersListQuery = useQuery(['usersList'], () => getUsersList({
    structureId: context.choiceEstablishment.id,
  }));
  const findUser = (userId) => {
    const a = getUsersListQuery?.data?.data?.users
      ?.map((user) => ({ ...user, name: `${user.firstName} ${user.lastName}` }))
      ?.find((user) => user.id === userId);
    return a;
  };

  const deleteInterventionMutation = useMutation(deleteIntervention, {
    onSuccess:
      () => {
        getInterventionsListQuery.refetch();
      },
  });

  const handleModal = (selectedInterventionToDelete) => {
    setOpenModal(true);
    setInterventionToDelete(selectedInterventionToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteInterventionMutation.mutate(trainingToDelete.id);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const columns = React.useMemo(
    () => [
      {
        Header: t('interventions.id'),
        accessor: 'id',
      },
      {
        Header: t('interventions.building'),
        accessor: 'building.name',
      },
      {
        Header: t('interventions.equipment'),
        accessor: 'equipment.name',
      },
      {
        Header: t('interventions.control'),
        accessor: 'control.name',
      },
      {
        Header: t('interventions.type'),
        accessor: 'controlType',
        Cell: ({ row: { original: { control } } }) => (
          <div>
            {t(`interventions.${control.interventionType}`)}
          </div>
        ),
      },
      {
        Header: t('interventions.title'),
        accessor: 'title',
      },
      {
        Header: t('interventions.calendar'),
        accessor: 'controlDate',
        Cell: ({
          row: {
            original: {
              control, nextInputStartDate, nextInputEndDate, nextReportDate,
            },
          },
        }) => (
          <div>
            {isInputIntervention(control) ? (
              <>
                <div>{formatDateToUser(nextInputStartDate)}</div>
                <div>{formatDateToUser(nextInputEndDate)}</div>
              </>
            ) : (
              <div>{formatDateToUser(nextReportDate)}</div>
            ) }
          </div>
        ),
      },
      {
        Header: t('interventions.frequency'),
        accessor: 'frequencyTitle',
      },
      {
        Header: t('interventions.user'),
        accessor: 'userId',
        Cell: ({ row: { original: { id, userId, societyId } } }) => (
          <div>
            <FontAwesomeIcon data-tip data-for={`userColResult${id}`} icon={faAddressCard} />
            <ReactTooltip id={`userColResult${id}`} place="top" type="info" effect="solid">
              {findUser(userId)?.name ?? findSociety(societyId)?.name ?? '-'}
            </ReactTooltip>
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/interventions/edit/${id}`}
              title={t('interventions.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <Button
              type="button"
              className="action suppr"
              label={<FontAwesomeIcon icon={faTrashCan} />}
              title={t('interventions.suppr')}
              onClick={() => handleModal({ id })}
            />
          </div>
        ),
      },
    ],
  );

  return (
    <>
      <Layout
        title={t('interventions.page_list_name')}
        queryError={getInterventionsListQuery.error
          || deleteInterventionMutation.error
          || getSocietiesListQuery.error
          || getUsersListQuery.error}
        layout="table"
      >
        <header className="header">
          <div className="row">
            <h1 className="title">{t('interventions.page_list_name')}</h1>
            <Link className="add" to="/interventions/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('interventions.add')}</span>
            </Link>
          </div>
          <div className="row">
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getSocietiesListQuery.isLoading
              || getUsersListQuery.isLoading
              || getInterventionsListQuery.isLoading}
            data={getInterventionsListQuery?.data?.data?.interventions || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getInterventionsListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
                numberElementsDisplayed={getInterventionsListQuery?.data?.data?.interventions?.length || 0}
              />
            )}
          />
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>

  );
}

Interventions.propTypes = {};

export default Interventions;
