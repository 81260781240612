import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

// components
import Picto from '../../../components/atoms/Picto/Picto';

// services
import { getEstablishmentsList } from '../../../services/structures';
import { getUsersList } from '../../../services/users';
import Card from '../../../components/atoms/Card/Card';

// styles
import styles from './AdminDashboardGrid.module.css';

// utils & misc
import cn from '../../../utils/cn';

function AdminDashboardGrid() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // API Calls
  const getEstablishmentsListQuery = useQuery(['establishments'], () => getEstablishmentsList());
  const getUsersListQuery = useQuery(['users'], () => getUsersList());

  return (
    <section className={styles.adminGrid}>
      {getEstablishmentsListQuery.isLoading || getUsersListQuery.isLoading ? (
        <div className="loader" />
      ) : (
        <>
          <Card className={styles.card} onClick={() => navigate('/etablissements')}>
            <div>
              <p className={cn([styles.title, styles.titleNumber])}>{t('navigation.establishments')}</p>
              <p className={cn([styles.title, styles.number])}>{getEstablishmentsListQuery?.data?.data?.total}</p>
            </div>
            <div className={styles.pictoCard}>
              <Picto name="etablissementsRoundedBlue" width="90" height="90" />
            </div>
          </Card>
          <Card className={styles.card} onClick={() => navigate('/users')}>
            <div>
              <p className={cn([styles.title, styles.titleNumber])}>{t('navigation.users')}</p>
              <p className={cn([styles.title, styles.number])}>{getUsersListQuery?.data?.data?.total}</p>
            </div>
            <div className={styles.pictoCard}>
              <Picto name="users" width="90" height="90" />
            </div>
          </Card>
          <Card className={styles.card} onClick={() => navigate('/somewhere')}>
            <div>
              <p className={cn([styles.title, styles.titleNumber])}>{t('global.search_motor')}</p>
            </div>
            <div>
              <FontAwesomeIcon className={styles.fileDownload} icon={faFileArrowDown} />
            </div>
          </Card>
          <Card className={styles.card} onClick={() => navigate('/somewhere')}>
            <div>
              <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.send_grouped')}</p>
            </div>
            <div className={styles.pictoCard}>
              <Picto name="contact" width="90" height="90" />
            </div>
          </Card>
          <Card className={styles.card} onClick={() => navigate('/depot-documents')}>
            <div>
              <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.document_deposit')}</p>
            </div>
            <div className={styles.pictoCard}>
              <Picto name="documentaire" width="90" height="90" />
            </div>
          </Card>
          <Card className={styles.card} onClick={() => navigate('/somewhere')}>
            <div>
              <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.indicator')}</p>
            </div>
            <div className={styles.pictoCard}>
              <Picto name="synthese" width="90" height="90" />
            </div>
          </Card>
        </>
      )}
    </section>
  );
}

export default AdminDashboardGrid;
