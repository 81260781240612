/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Input from '../../../atoms/Input/Input';

function NumberThriceTime({
  label, value, onChange, id,
}) {
  const currentDate = new Date();
  const currentHours = (`0${currentDate.getHours()}`).slice(-2);
  const currentMinutes = (`0${currentDate.getMinutes()}`).slice(-2);
  const currentTime = `${currentHours}:${currentMinutes}`;

  return (
    <div className="inputForm">
      <div className="inputForm_control inputForm_label">
        {label}
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={`number-1-${id}`}
          name={`number-1-${id}`}
          label={label}
          labelHidden
          type="number"
          value={value.degreeAt30s}
          onChange={(e) => onChange({ ...value, degreeAt30s: e.target.value })}
          step="0.1"
          required={!!value.isDirty}
        />
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={`number-3-${id}`}
          name={`number-3-${id}`}
          label={label}
          labelHidden
          type="number"
          value={value.stabilizationDegree}
          onChange={(e) => onChange({ ...value, stabilizationDegree: e.target.value })}
          step="0.1"
          required={!!value.isDirty}
        />
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={`number-2-${id}`}
          name={`number-2-${id}`}
          label={label}
          labelHidden
          type="number"
          value={value.stabilizationTime}
          onChange={(e) => onChange({ ...value, stabilizationTime: e.target.value })}
          step="1"
          required={!!value.isDirty}
        />
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={`time-${id}`}
          name={`time-${id}`}
          type="time"
          value={value.time || (value?.stabRecordDate !== null && value?.stabRecordDate.substring(11, 16)) || currentTime}
          onChange={(e) => onChange({ ...value, time: e.target.value })}
          label={label}
          labelHidden
          required={!!value.isDirty}
        />
      </div>
    </div>
  );
}

NumberThriceTime.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.objectOf({
    degreeAt30s: PropTypes.number,
    stabilizationTime: PropTypes.number,
    stabilizationDegree: PropTypes.number,
    time: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default NumberThriceTime;
