import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Radio from '../../components/atoms/Radio/Radio';
// Services
import { getBuildingTemplate, postBuildingTemplate, editBuildingTemplate } from '../../services/structures';
import { getEquipmentsList } from '../../services/equipments';

const initialValues = {
  name: '',
  bookletId: '',
  equipmentIds: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.required_field'),
  bookletId: Yup.number().required('global.required_field'),
  equipmentIds: Yup.array().min(1, 'global.required_field'),
});

function AddBuildingTemplate() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  // API calls
  const getEquipmentsListQuery = useQuery('equipments', () => getEquipmentsList());
  const postBuildingTemplateMutation = useMutation(postBuildingTemplate, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_building_template.building_template_added'));
    },
  });
  const editBuildingTemplateMutation = useMutation(editBuildingTemplate, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_building_template.building_template_edited'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (urlParams?.action === 'edit') {
        editBuildingTemplateMutation.mutate(formValues);
      } else {
        postBuildingTemplateMutation.mutate(formValues);
      }
    },
  });

  const getBuildingTemplateQuery = useQuery('building-template', () => getBuildingTemplate(urlParams.id), {
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues(data.data);
    },
  });

  const handleCheckboxClick = (fieldName, fieldValue, isChecked) => formik.setFieldValue(
    fieldName,
    isChecked
      ? formik.values[fieldName].concat(fieldValue)
      : formik.values[fieldName].filter((id) => id !== fieldValue),
  );

  return (
    <Layout
      title={t('add_building_template.title')}
      queryError={
        postBuildingTemplateMutation.error
        || editBuildingTemplateMutation.error
        || getEquipmentsListQuery.error
        || getBuildingTemplateQuery.error
      }
    >
      <header className="header">
        <h1 className="title">
          {urlParams?.action === 'edit' ? t('add_building_template.edit_title') : t('add_building_template.title')}
        </h1>
      </header>
      <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
        <div className="form_group">
          <Input
            id="name"
            type="text"
            name="name"
            label={t('add_building_template.name')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name ? (
            <div className="error">
              {t(formik.errors.name)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_building_template.booklet')}</div>
          <div>
            <Radio
              id={t('add_building_template.booklet_security')}
              name="bookletId"
              label={t('add_building_template.booklet_security')}
              onChange={(event) => formik.setFieldValue('bookletId', parseInt(event.target.value, 10))}
              onBlur={formik.handleBlur}
              checked={formik.values.bookletId === 2}
              value={2}
            />
          </div>
          <div>
            <Radio
              id={t('add_building_template.booklet_sanitary')}
              name="bookletId"
              label={t('add_building_template.booklet_sanitary')}
              onChange={(event) => formik.setFieldValue('bookletId', parseInt(event.target.value, 10))}
              onBlur={formik.handleBlur}
              checked={formik.values.bookletId === 1}
              value={1}
            />
          </div>
          {formik.errors.bookletId && formik.touched.bookletId ? (
            <div className="error">
              {t(formik.errors.bookletId)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_building_template.equipments')}</div>
          {sortBy(getEquipmentsListQuery?.data?.data?.equipments, ['name']).map((equipment) => (
            equipment.bookletIds.includes(formik.values.bookletId) ? (
              <div>
                <Checkbox
                  id={`equipment-${equipment.id}`}
                  name="equipmentIds[]"
                  label={equipment.name}
                  onChange={(event) => handleCheckboxClick('equipmentIds', equipment.id, event.target.checked)}
                  onBlur={formik.handleBlur}
                  checked={formik.values.equipmentIds?.includes(equipment.id)}
                  value={equipment.id}
                />
              </div>
            ) : null
          ))}
          {!formik.values.bookletId ? (
            <div className="info">
              {t('global.choose_booklet_first')}
            </div>
          ) : null}
          {formik.errors.equipmentIds && formik.touched.equipmentIds ? (
            <div className="error">
              {t(formik.errors.equipmentIds)}
            </div>
          ) : null }
        </div>
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('add_building_template.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('add_building_template.submit')}
            isLoading={postBuildingTemplateMutation.isLoading}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_building_template.back_to_buildings_templates')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddBuildingTemplate;
