import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Hooks
import useAppContext from '../../../store/useAppContext';
// Components
import CardDashboard from '../../../components/molecules/CardDashboard/CardDashboard';
// Styles
import styles from './UserDashboard.module.css';

function UserDashboard() {
  const [context] = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSecurityBooklet = context.choiceBooklet === 2;

  if (isSecurityBooklet) {
    return (
      <>
        <section className={styles.gridTwoColumns}>
          <CardDashboard
            title={t('dashboard.my_ledger')}
            picto="monRegistreBlanc"
            classNames={[styles.cardLedger]}
            onClick={() => navigate('/')}
          />
          <CardDashboard
            title={t('dashboard.documentary_base')}
            picto="documentaire"
            classNames={[]}
            onClick={() => navigate('/')}
            borderTopColored
          />
        </section>
        {/* 2nd line */}
        <section className={styles.gridFourColumns}>
          <CardDashboard
            title={t('dashboard.journal')}
            picto="journalBlanc"
            classNames={[styles.cardSecurityTheme]}
            onClick={() => navigate('/')}
          />
          <CardDashboard
            title={t('dashboard.input')}
            picto="saisiesBlanc"
            classNames={[styles.cardSecurityTheme]}
            onClick={() => navigate('/saisies')}
          />
          <CardDashboard
            title={t('dashboard.filing_reports')}
            picto="depotRapportBlanc"
            classNames={[styles.cardSecurityTheme]}
            onClick={() => navigate('/rapports')}
          />
          <CardDashboard
            title={t('dashboard.filling_other_documents')}
            picto="depotDocBlanc"
            classNames={[styles.cardSecurityTheme]}
            onClick={() => navigate('/')}
          />
        </section>
        {/* 3rd line */}
        <section className={styles.gridFourColumns}>
          <CardDashboard
            title={t('dashboard.calendar')}
            picto="calendrier"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/')}
          />
          <CardDashboard
            title={t('dashboard.providers')}
            picto="prestataires"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/')}
          />
          <CardDashboard
            title={t('dashboard.contracts')}
            picto="contrats"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/')}
          />
          <CardDashboard
            title={t('dashboard.trainings')}
            picto="equipements"
            classNames={[styles.cardBlueLightedTheme]}
            onClick={() => navigate('/formations')}
          />
        </section>
      </>
    );
  }
  // Sanitaire
  return (
    <>
      <section className={styles.gridTwoColumns}>
        <CardDashboard
          title={t('dashboard.my_book')}
          picto="monRegistreBlanc"
          classNames={[styles.cardBook]}
          onClick={() => navigate('/')}
        />
        <CardDashboard
          title={t('dashboard.documentary_base')}
          picto="documentaire"
          classNames={[]}
          onClick={() => navigate('/')}
          borderTopColored
        />
      </section>
      <section className={styles.gridFourColumns}>
        <CardDashboard
          title={t('dashboard.journal')}
          picto="journalBlanc"
          classNames={[styles.cardSanitaryTheme]}
          onClick={() => navigate('/')}
        />
        <CardDashboard
          title={t('dashboard.input')}
          picto="saisiesBlanc"
          classNames={[styles.cardSanitaryTheme]}
          onClick={() => navigate('/saisies')}
        />
        <CardDashboard
          title={t('dashboard.filing_reports')}
          picto="depotRapportBlanc"
          classNames={[styles.cardSanitaryTheme]}
          onClick={() => navigate('/rapports')}
        />
        <CardDashboard
          title={t('dashboard.filling_other_documents')}
          picto="depotDocBlanc"
          classNames={[styles.cardSanitaryTheme]}
          onClick={() => navigate('/')}
        />
      </section>
      <section className={styles.gridFourColumns}>
        <CardDashboard
          title={t('dashboard.calendar')}
          picto="calendrier"
          classNames={[styles.cardBlueLightedTheme]}
          onClick={() => navigate('/')}
        />
        <CardDashboard
          title={t('dashboard.providers')}
          picto="prestataires"
          classNames={[styles.cardBlueLightedTheme]}
          onClick={() => navigate('/')}
        />
        <CardDashboard
          title={t('dashboard.contracts')}
          picto="contrats"
          classNames={[styles.cardBlueLightedTheme]}
          onClick={() => navigate('/')}
        />
        <CardDashboard
          title={t('dashboard.analysis')}
          picto="equipements"
          classNames={[styles.cardBlueLightedTheme]}
          onClick={() => navigate('/')}
        />
      </section>
    </>
  );
}

UserDashboard.propTypes = {};

export default UserDashboard;
