/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { debounce } from 'lodash';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faPlus, faTrashCan, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Components
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import {
  getStructuresList,
  deleteGroup,
  deleteDivision,
  deleteSubsidiary,
} from '../../services/structures';
// Utils
import styles from './Structures.module.css';

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '14.375rem',
    padding: '2px',
    border: '2px solid #dcdee8',
    borderRadius: '1.875rem',
  }),
};

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Structure() {
  // Hooks
  const { t } = useTranslation();
  const {
    filters, onFilterChange, page, onPageChange,
  } = useTableFiltersPagination({ initialFilters: { types: [] } });
  const options = [
    {
      value: 'Group',
      label: `${t('structures.group')}`,
    },
    {
      value: 'Subsidiary',
      label: `${t('structures.subsidiary')}`,
    },
    {
      value: 'Division',
      label: `${t('structures.division')}`,
    },
  ];
  // States
  const [openModal, setOpenModal] = useState(false);
  const [structureToDelete, setStructureToDelete] = useState({
    id: null,
    type: '',
  });
  const [search, setSearch] = useState('');

  // API Calls
  const getStructuresListQuery = useQuery(
    ['structures', page, filters, search],
    () => getStructuresList({ page, search }, filters),
  );
  const deleteGroupMutation = useMutation(deleteGroup, {
    onSuccess:
      () => {
        getStructuresListQuery.refetch();
      },
  });
  const deleteSubsidiaryMutation = useMutation(deleteSubsidiary, {
    onSuccess:
      () => {
        getStructuresListQuery.refetch();
      },
  });
  const deleteDivisionMutation = useMutation(deleteDivision, {
    onSuccess:
      () => {
        getStructuresListQuery.refetch();
      },
  });

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const handleModal = (selectedStructureToDelete) => {
    setOpenModal(true);
    setStructureToDelete(selectedStructureToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    switch (structureToDelete.type) {
      case 'Group':
        return deleteGroupMutation.mutate(structureToDelete.id);
      case 'Subsidiary':
        return deleteSubsidiaryMutation.mutate(structureToDelete.id);
      case 'Division':
        return deleteDivisionMutation.mutate(structureToDelete.id);
      default:
        return undefined;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('structures.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('structures.type')}`,
        accessor: 'type',
        Cell: ({ row: { original: { type } } }) => (
          <>
            <span>{type === 'Group' ? 'Groupe' : null}</span>
            <span>{type === 'Subsidiary' ? 'Filiale' : null}</span>
            <span>{type === 'Division' ? 'Division' : null}</span>
          </>
        ),
      },
      {
        Header: `${t('structures.label')}`,
        accessor: 'name',
      },
      {
        Header: `${t('structures.group')}`,
        accessor: 'groupName',
      },
      {
        Header: `${t('structures.establishment')}`,
        accessor: 'countEstablishment',
      },
      {
        Header: ' ',
        maxWidth: 50,
        Cell: ({ row: { original: { id, type } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/structures/${type}/edit/${id}`}
              title={t('structures.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button
              type="button"
              className="action suppr"
              title={t('structures.suppr')}
              onClick={() => handleModal({ id, type })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  const handleFilterChange = (filterValueList) => {
    onFilterChange(filterValueList.map((filterOption) => filterOption.value), 'types');
  };

  return (
    <>
      <Layout
        title={t('structures.title')}
        queryError={
          getStructuresListQuery.error
          || deleteGroupMutation.error
          || deleteSubsidiaryMutation.error
          || deleteDivisionMutation.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row">
            <h1 className="title">{t('structures.title')}</h1>
            <Link className="add" to="/structures/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('structures.add')}</span>
            </Link>
          </div>
          <div className="row">
            <Select
              styles={customSelectStyles}
              isMulti
              isClearable
              options={options}
              placeholder={t('global.choose')}
              noOptionsMessage={() => t('global.no_options_available')}
              defaultValue={filters.types.length > 0
                ? options.filter((option) => filters.types.includes(option.value))
                : null}
              onChange={handleFilterChange}
            />
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getStructuresListQuery.isLoading}
            data={getStructuresListQuery?.data?.data?.structures || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getStructuresListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
                numberElementsDisplayed={getStructuresListQuery?.data?.data?.structures?.length || 0}
              />
            )}
          />
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default Structure;
