import api from './_api';

// GROUPS

/**
 * GET - Get reports list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getReportsList = (queryParams) => api.get('/reports', { params: queryParams });

/**
 * GET - Get report
 * @param {Number} reportId
 * @returns {Promise<AxiosResponse>}
 */
export const getReport = (reportId) => api.get(`/reports/${reportId}`);

/**
 * POST - Create report
 * @param {{
 * id: Number,
 * interventionId: Number,
 * societyInChargeId: Number,
 * observations: Array,
 * reference: String,
 * reportDate: Date,
 * reportFile: File,
 * }}
 * @returns {Promise<AxiosResponse>}
 */
export const postReport = (reportToCreate) => {
  const formData = new FormData();
  formData.append('id', reportToCreate.id);
  formData.append('interventionId', reportToCreate.interventionId);
  formData.append('societyInChargeId', reportToCreate.societyInChargeId);
  reportToCreate.observations.forEach((observation, index) => {
    formData.append(`observations[${index}][title]`, observation.name);
    formData.append(`observations[${index}][number]`, observation.number);
  });
  formData.append('reference', reportToCreate.reference);
  formData.append('reportDate', reportToCreate.reportDate);
  if (reportToCreate.reportFile) {
    formData.append('reportFile', reportToCreate.reportFile);
  }
  api.post('/reports', formData);
};

/**
 * PUT - Edit report
 * @param {{
 * id: Number,
 * interventionId: Number,
 * societyInChargeId: Number,
 * observations: Array,
 * reference: String,
 * reportDate: Date,
 * reportFile: File,
 * }}
 * @returns {Promise<AxiosResponse>}
 */
export const editReport = (reportToEdit) => {
  const formData = new FormData();
  formData.append('id', reportToEdit.id);
  formData.append('interventionId', reportToEdit.interventionId);
  formData.append('societyInChargeId', reportToEdit.societyInChargeId);
  reportToEdit.observations.forEach((observation, index) => {
    formData.append(`observations[${index}][title]`, observation.name);
    formData.append(`observations[${index}][number]`, observation.number);
  });
  formData.append('reference', reportToEdit.reference);
  formData.append('reportDate', reportToEdit.reportDate);
  if (reportToEdit.reportFile) {
    formData.append('reportFile', reportToEdit.reportFile);
  }
  api.post(`/reports/${reportToEdit.id}`, formData);
};
