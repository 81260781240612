import React from 'react';
import PropTypes from 'prop-types';
// Components
import ControlType from '../../template/ControlType/ControlType';
import ProgressLink from '../InputTypes/ProgressLink/ProgressLink';

function SafetyLightning({ list }) {
  // Hooks

  const columns = [
    {
      label: '',
    },
  ];

  return (
    <ControlType columns={columns} noSubmit>
      {list?.map((input) => (
        <ProgressLink
          key={input.id}
          label={input.title}
          value={{
            value: input.numberOfCompleted,
            valueMax: input.totalToComplete,
            link: `/saisies/eclairage-securite/${input.id}`,
          }}
        />
      ))}
    </ControlType>
  );
}

SafetyLightning.propTypes = {
  list: PropTypes.array.isRequired,
};

export default SafetyLightning;
