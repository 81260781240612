import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
// Components
import Layout from '../../components/template/Layout';
import Button from '../../components/atoms/Button/Button';
import InputsTypeSwitcher from '../../components/molecules/InputType/InputsTypeSwitcher';
// Hooks
import useAppContext from '../../store/useAppContext';
// Services
import { getInputsToComplete, postInputs } from '../../services/inputs';
import { getBuildingsList } from '../../services/structures';
// Utils
import styles from './Inputs.module.css';
import cn from '../../utils/cn';

function Inputs() {
  // Hooks
  const { t } = useTranslation();
  const [context] = useAppContext();
  const [buildingId, setBuildingId] = useState(context?.choiceEstablishment?.buildingIds[0]);
  // API Calls
  const getInputsToCompleteQuery = useQuery(['inputs', buildingId], () => getInputsToComplete({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
    buildingId,
  }));

  const postInputsMutation = useMutation(postInputs, {
    onSuccess: () => {
      getInputsToCompleteQuery.refetch();
      toast.success(t('inputs.saved'));
    },
  });

  const handleSubmit = (inputsToSubmit) => {
    if (inputsToSubmit.length) {
      postInputsMutation.mutate({
        inputs: inputsToSubmit,
      });
    } else {
      toast.info(t('inputs.nothing_to_save'));
    }
  };

  const inputsControlTypeSorted = getInputsToCompleteQuery?.data?.data?.inputs
    ? getInputsToCompleteQuery?.data?.data?.inputs.reduce((acc, input) => {
      const isAlreadyInAcc = acc.some((accInput) => accInput.controlTypeId === input.controlTypeId);
      if (!isAlreadyInAcc) {
        acc.push({
          controlTypeId: input.controlTypeId,
          controlTypeName: input.controlTypeName,
          inputsControlList: [input],
        });
        return acc;
      }
      return acc.map((accInput) => {
        if (accInput.controlTypeId === input.controlTypeId) {
          return {
            ...accInput,
            inputsControlList: accInput.inputsControlList.concat(input),
          };
        }
        return accInput;
      });
    }, []) : null;

  const inputsInputTypeSorted = inputsControlTypeSorted?.map((inputControlType) => ({
    ...inputControlType,
    inputsControlList: inputControlType.inputsControlList.reduce((acc, input) => {
      const isAlreadyInAcc = acc.some((accInput) => accInput.inputType === input.inputType);
      if (!isAlreadyInAcc) {
        acc.push({
          inputType: input.inputType,
          inputsTypeList: [input],
        });
        return acc;
      }
      return acc.map((accInput) => {
        if (accInput.inputType === input.inputType) {
          return {
            ...accInput,
            inputsTypeList: accInput.inputsTypeList.concat(input),
          };
        }
        return accInput;
      });
    }, []),
  }));

  const getBuildingsQuery = useQuery('buildings', () => getBuildingsList({
    bookletId: context?.choiceBooklet,
    establishmentId: context?.choiceEstablishment?.id,
  }), {
    onSuccess: (data) => data?.data?.buildings?.length > 0 && setBuildingId(data.data.buildings[0].id),
  });

  const handleBuilding = (id) => {
    setBuildingId(id);
  };

  return (
    <Layout
      title={t('inputs.title')}
      queryError={
        getInputsToCompleteQuery.error
        || getBuildingsQuery.error
        || postInputsMutation.error
      }
      layout="table"
    >
      <header className="header">
        <div className="row">
          <h1 className="title">{t('inputs.title')}</h1>
          <Link
            className="add"
            type="button"
            to="/saisies/historique"
          >
            <FontAwesomeIcon icon={faClockRotateLeft} />
            {' '}
            {t('inputs.input_historic')}
          </Link>
        </div>
        <div className={styles.filters}>
          <div className={styles.filtersToComplete}>
            {getInputsToCompleteQuery?.data?.data?.buildingInputInfos?.map((building) => (
              building.countInputsToComplete !== 0 ? (
                <Button
                  key={building.buildingId}
                  type="button"
                  label={`(${building.countInputsToComplete}) ${building.buildingName}`}
                  className={cn([styles.filter, buildingId === building.buildingId
                    ? styles.active
                    : '', 'shadow-md'])}
                  onClick={() => handleBuilding(building.buildingId)}
                />
              ) : null
            ))}
          </div>
          <div className={styles.filtersCompleted}>
            {getInputsToCompleteQuery?.data?.data?.buildingInputInfos?.map((building) => (
              building.countInputsToComplete === 0 ? (
                <Button
                  key={building.buildingId}
                  type="button"
                  label={building.buildingName}
                  className={cn([styles.filter, buildingId === building.buildingId
                    ? styles.active
                    : '', styles.completed, 'shadow-md'])}
                  onClick={() => handleBuilding(building.buildingId)}
                />
              ) : null
            ))}
          </div>
        </div>
      </header>
      <div className={styles.inputs}>
        {getInputsToCompleteQuery.isLoading ? (
          <div className="loader" />
        )
          : inputsInputTypeSorted?.map((inputControlType) => (
            <div key={`row-${inputControlType.controlTypeName}`} className={styles.controlTypeBloc}>
              <h2 className={styles.controlTypeTitle}>{inputControlType.controlTypeName}</h2>
              <div>
                {inputControlType?.inputsControlList?.map((inputControl) => (
                  <InputsTypeSwitcher
                    key={inputControl.inputType}
                    inputControl={inputControl}
                    submit={handleSubmit}
                  />
                ))}
              </div>
            </div>
          )) || <div>{t('inputs.no_inputs')}</div>}
      </div>
    </Layout>
  );
}

export default Inputs;
