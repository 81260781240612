// Views
import Home from '../views/Home/Home';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import Equipments from '../views/Equipments/Equipments';
import AddEquipment from '../views/AddEquipment/AddEquipment';
import EquipmentsFamilies from '../views/EquipmentsFamilies/EquipmentsFamilies';
import AddEquipmentFamily from '../views/AddEquipmentFamily/AddEquipmentFamily';
import Controls from '../views/Controls/Controls';
import AddControl from '../views/AddControl/AddControl';
import ControlTypes from '../views/ControlTypes/ControlTypes';
import AddControlType from '../views/AddControlType/AddControlType';
import BuildingTemplates from '../views/BuildingTemplates/BuildingTemplates';
import AddBuildingTemplate from '../views/AddBuildingTemplate/AddBuildingTemplate';
import Structures from '../views/Structures/Structures';
import AddStructure from '../views/AddStructure/AddStructure';
import Establishments from '../views/Establishments/Establishments';
import AddEstablishment from '../views/AddEstablishment/AddEstablishment';
import Users from '../views/Users/Users';
import AddUser from '../views/AddUser/AddUser';
import Trainings from '../views/Trainings/Trainings';
import AddTraining from '../views/AddTraining/AddTraining';
import ChoiceBooklet from '../views/ChoiceBooklet/ChoiceBooklet';
import ChoiceEstablishment from '../views/ChoiceEstablishment/ChoiceEstablishment';
import Inputs from '../views/Inputs/Inputs';
import Dashboard from '../views/Dashboard/Dashboard';
import AddBuilding from '../views/AddBuilding/AddBuilding';
import DocumentDeposit from '../views/DocumentDeposit/DocumentDeposit';
import AddEquipmentLightningSecurity from '../views/AddEquipmentLightningSecurity/AddEquipmentLightningSecurity';
import Interventions from '../views/Interventions/Interventions';
import AddIntervention from '../views/AddIntervention/AddIntervention';
import FillingReports from '../views/FillingReports/FillingReports';
import AddFillingReports from '../views/AddFillingReport/AddFillingReport';
import InputSafetyLightingEquipment from '../views/InputSafetyLightingEquipment/InputSafetyLightingEquipment';
import HistoricInputs from '../views/HistoricInputs/HistoricInputs';

// Utils
import { USERS_ROLES } from '../utils/constant';
import EditHistoricInput from '../views/EditHistoricInput/EditHistoricInput';

export const publicRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
];

export const privateRoutes = [
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/choix-carnet',
    element: <ChoiceBooklet />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/choix-etablissement',
    element: <ChoiceEstablishment />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/depot-documents',
    element: <DocumentDeposit />,
  },
  {
    path: '/rapports',
    element: <FillingReports />,
  },
  {
    path: '/rapports/:action',
    element: <AddFillingReports />,
  },
  {
    path: '/rapports/:action/:id',
    element: <AddFillingReports />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/formations',
    element: <Trainings />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/formations/:action',
    element: <AddTraining />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/formations/:action/:id',
    element: <AddTraining />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/saisies',
    element: <Inputs />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/saisies/historique',
    element: <HistoricInputs />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/saisies/historique/:action/:id',
    element: <EditHistoricInput />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/saisies/eclairage-securite/:inputId',
    element: <InputSafetyLightingEquipment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/interventions',
    element: <Interventions />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/interventions/:action',
    element: <AddIntervention />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/interventions/:action/:id',
    element: <AddIntervention />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/safetyLightingEquipments/:action/:id',
    element: <AddEquipmentLightningSecurity />,
  },
  {
    avalaibleToRole: [USERS_ROLES.USER],
    path: '/safetyLightingEquipments/:action/',
    element: <AddEquipmentLightningSecurity />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/structures',
    element: <Structures />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/etablissements',
    element: <Establishments />,
  },
  // TODO : NOT STANDART WAY TO NESTED ROUTES, NEED REFACTO !
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/etablissements/:action/:id',
    element: <AddEstablishment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/etablissements/add',
    element: <AddEstablishment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/etablissements/:establishmentParentId/batiments/add',
    element: <AddBuilding />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/etablissements/:establishmentParentId/batiments/:action/:id',
    element: <AddBuilding />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/equipements',
    element: <Equipments />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/equipements-familles',
    element: <EquipmentsFamilies />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/controles',
    element: <Controls />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/controles-types',
    element: <ControlTypes />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/batiments-modeles',
    element: <BuildingTemplates />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/users',
    element: <Users />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/users/add',
    element: <AddUser />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/users/:action/:id',
    element: <AddUser />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/equipements-familles/add',
    element: <AddEquipmentFamily />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/equipements-familles/:action/:id',
    element: <AddEquipmentFamily />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/equipements/add',
    element: <AddEquipment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/equipements/:action/:id',
    element: <AddEquipment />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/structures/add',
    element: <AddStructure />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/structures/:structure/:action/:id',
    element: <AddStructure />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/controles/add',
    element: <AddControl />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/controles/:action/:id',
    element: <AddControl />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/controles-types/add',
    element: <AddControlType />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/controles-types/:action/:id',
    element: <AddControlType />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/batiments-modeles/add',
    element: <AddBuildingTemplate />,
  },
  {
    avalaibleToRole: [USERS_ROLES.ADMIN, USERS_ROLES.SUPER_ADMIN],
    path: '/batiments-modeles/:action/:id',
    element: <AddBuildingTemplate />,
  },
];
