import React from 'react';
import PropTypes from 'prop-types';
// Components
import Input from '../../../atoms/Input/Input';

function Number({
  label, value, onChange, id, step,
}) {
  return (
    <div className="inputForm">
      <div className="inputForm_control inputForm_label">
        {label}
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={id}
          type="number"
          labelHidden
          value={value.number
            || value?.chlorineRate
            || value?.weight
            || value?.numberOfSwimmers
            || value?.ph
            || value?.hydrometricDegree}
          onChange={(e) => onChange({ ...value, number: e.target.value })}
          step={step}
        />
      </div>
    </div>
  );
}

Number.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.string.isRequired,
  step: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};
Number.defaultProps = {
  step: 1,
};

export default Number;
