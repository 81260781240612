/* eslint-disable react/jsx-props-no-spreading */
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ToastContainer } from 'react-toastify';
import {
  AppProvider,
  AppStateContext,
} from './store/AppContext';

// Routes
import { publicRoutes, privateRoutes } from './routes';

// Utils
import 'react-toastify/dist/ReactToastify.min.css';

// React Query Client config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <>
      <ToastContainer position="top-center" />
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <AppStateContext.Consumer>
            {(context) => (
              <BrowserRouter>
                <Routes>
                  {[...context.user ? privateRoutes : publicRoutes]
                    .map((route) => (!route.avalaibleToRole || route?.avalaibleToRole
                      .includes(context?.userImpersonated?.role || context?.user?.role) ? (
                        <Route key={route.path} {...route} />
                      ) : null)) }
                  <Route
                    path="*"
                    element={<Navigate to={context.user ? '/dashboard' : '/'} replace />}
                  />
                </Routes>
              </BrowserRouter>
            )}
          </AppStateContext.Consumer>
        </AppProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
