/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import SelectComp from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Utils
import styles from './Select.module.css';
import cn from '../../../utils/cn';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '2.75rem',
    borderColor: 'var(--color-grey-200)',
    borderRadius: '0.3rem',
  }),
};

function Select({
  id, label, ref, labelHidden, options, disabled, required, isMulti, ...props
}) {
  // Hooks
  const { t } = useTranslation();

  return (
    <>
      <label className={cn([styles.label, labelHidden ? 'visually-hidden' : ''])} htmlFor={id}>
        {label}
        {' '}
        {required ? '*' : ''}
      </label>
      <SelectComp
        styles={customStyles}
        className={styles.select}
        id={id}
        ref={ref}
        options={options}
        placeholder={t('global.choose')}
        noOptionsMessage={() => t('global.no_options_available')}
        isDisabled={disabled}
        isMulti={isMulti}
        {...props}
      />
    </>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  ref: PropTypes.func,
  labelHidden: PropTypes.bool,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isMulti: PropTypes.bool,
};
Select.defaultProps = {
  ref: () => {},
  labelHidden: false,
  disabled: false,
  required: false,
  isMulti: false,
};

export default Select;
