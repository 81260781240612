/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus } from '@fortawesome/free-solid-svg-icons';

// Component
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Button from '../../components/atoms/Button/Button';
import UploadFile from '../../components/atoms/UploadFile/UploadFile';

// hooks
import useAppContext from '../../store/useAppContext';

// services
import { editReport, postReport } from '../../services/reports';
import { getIntervention } from '../../services/interventions';
import { getSociety } from '../../services/societies';

// styles
import styles from './AddFillingReport.module.css';
import cn from '../../utils/cn';

function AddFillingReport() {
  const { t } = useTranslation();
  const urlParams = useParams();
  const navigate = useNavigate();
  const [context] = useAppContext();
  const { state } = useLocation();

  const isEditing = urlParams.action === 'edit';
  const isViewing = urlParams.action === 'view';
  const isCreating = !isEditing && !isViewing;
  const isSecurityBooklet = context.choiceBooklet === 2;

  const goBackUrl = (message) => {
    navigate(-1);
    toast.success(message);
  };

  const initialValues = {
    reportDate: '',
    interventionId: state.interventionId,
    societyInChargeName: state.societyInChargeName,
    reference: null,
    reportFile: null,
    observations: [],
  };

  const validationSchema = Yup.object().shape({
    reportDate: Yup.string().nullable().required(t('global.required_field')),
    societyInChargeName: Yup.string().nullable().typeError(t('global.wrong_type')),
    reference: Yup.string().nullable().typeError(t('global.wrong_type')),
    reportFile: Yup.mixed().nullable().typeError(t('global.wrong_type')).required('global.required_field')
      .test('fileSize', 'global.file_too_large', (value) => value && value.size <= 10000000)
      .test('type', 'global.accepted_formats', (value) => value && (value.type === 'application/pdf'
        || value.type === 'application/x-pdf'
        || value.type === 'image/jpeg'
        || value.type === 'image/jpg'
        || value.type === 'image/png'
        || value.type === 'image/tiff'
        || value.type === 'image/bmp'
        || value.type === 'image/heic'
        || value.type === 'image/vnd.dwg')),
    observations: Yup.array().of(Yup.object().shape({
      name: Yup.string().nullable().required(t('global.required_field')).typeError(t('global.wrong_type')),
      number: Yup.string().nullable().required(t('global.required_field')).typeError(t('global.wrong_type')),
    })),
  });

  const postReportMutation = useMutation(postReport, {
    onSuccess: () => goBackUrl(t('addReports.added')),
  });
  const editReportMutation = useMutation(editReport, {
    onSuccess: () => goBackUrl(t('addReports.edited')),
  });

  const getInterventionQuery = useQuery('intervention', () => getIntervention(state.interventionId), { enabled: !!state.interventionId });
  const getSocietyQuery = useQuery('society', () => getSociety(state.societyInChargeId), { enabled: !!state.societyInChargeId });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      const valuesToSave = {
        ...formValues,
      };

      if (isEditing) {
        editReportMutation.mutate(valuesToSave);
      } else {
        postReportMutation.mutate(valuesToSave);
      }
    },
  });

  return (
    <Layout
      title={isCreating
        ? t('addReports.title_create')
        : t('addReports.title_edit')}
      queryError={editReportMutation.error || postReportMutation.error}
    >
      <header className="header">
        <h1 className="title">
          {t('addReports.title_deposit')}
        </h1>
      </header>
      <form onSubmit={formik.handleSubmit} className="form shadow-sm">
        <div className="form_group">
          <Input
            id="reportDate"
            name="reportDate"
            type="date"
            label={t('addReports.report_date')}
            value={formik.values.reportDate}
            onChange={(e) => formik.setFieldValue('reportDate', e.target.value)}
            min="2000-01-01"
            max="2099-12-31"
          />
          {formik.errors.reportDate && formik.touched.reportDate ? (
            <div className="error">
              {t(formik.errors.reportDate)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('addReports.report')}</div>
          <UploadFile
            id="documentFile"
            name="documentFile"
            label={t('addReports.report')}
            fileName={formik.values.reportFile ? formik.values.reportFile.name : formik.values.reportFileName}
            onChange={(event) => formik.setFieldValue('reportFile', event.currentTarget.files[0])}
            onBlur={formik.handleBlur}
          />
          {formik.errors.reportFile && formik.touched.reportFile ? (
            <div className="error">
              {t(formik.errors.reportFile)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            id="reference"
            name="reference"
            type="text"
            label={t('addReports.report_number')}
            value={formik.values.reference}
          />
          {formik.errors.reference && formik.touched.reference ? (
            <div className="error">
              {t(formik.errors.reference)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <Input
            id="societyInChargeName"
            name="societyInChargeName"
            type="text"
            label={t('addReports.society')}
            value={formik.values.societyInChargeName || '-'}
            disabled
          />
          {formik.errors.societyInChargeName && formik.touched.societyInChargeName ? (
            <div className="error">
              {t(formik.errors.societyInChargeName)}
            </div>
          ) : null }
        </div>

        <div className="form_group">
          <span className="label">
            {t('addReports.report_type')}
            {' '}
            :
          </span>
          {' '}
          {getInterventionQuery && getInterventionQuery?.data?.data?.equipment.name === 'Commission De Sécurité'
            ? t('addReports.safety_committee')
            : t(`addReports.${getSocietyQuery?.data?.data?.type}`)}
        </div>

        { isSecurityBooklet
          ? (
            <section className="observations form_group form_group--fullwidth form_group--highlighted">
              <h3 className="title">{t('addReports.observations')}</h3>
              {formik.values.observations.length ? formik.values.observations.map((observation, index) => (
                <div className="form_row">
                  <div className="form_group">
                    <Input
                      id="observationName"
                      name="observationName"
                      type="text"
                      label={t('addReports.name')}
                      value={observation.name}
                      onChange={(e) => formik.setFieldValue(
                        'observations',
                        formik.values.observations.map((obser, indexObservation) => (indexObservation === index
                          ? { name: e.target.value, number: obser.number }
                          : obser)),
                      )}
                    />
                    {formik.errors.observations && formik?.errors?.observations?.[index]?.name && formik?.touched?.observations?.[index]?.name ? (
                      <div className="error">
                        {formik.errors.observations?.[index]?.name}
                      </div>
                    ) : null }
                  </div>
                  <div className="form_group">
                    <Input
                      id="observationNumber"
                      name="observationNumber"
                      type="text"
                      label={t('addReports.number')}
                      value={observation.number}
                      onChange={(e) => formik.setFieldValue(
                        'observations',
                        formik?.values?.observations?.map((obser, indexObservation) => (indexObservation === index
                          ? { name: obser.name, number: e.target.value }
                          : obser)),
                      )}
                    />
                    {formik.errors.observations && formik?.errors?.observations?.[index]?.number && formik?.touched?.observations?.[index]?.number ? (
                      <div className="error">
                        {formik?.errors?.observations?.[index]?.number}
                      </div>
                    ) : null }
                  </div>
                </div>
              )) : null }
              <Button
                type="button"
                className={cn([styles.addObsButton, 'form_button'])}
                label={t('addReports.add_observations')}
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => formik.setFieldValue('observations', formik.values.observations.concat({ name: '', number: '' }))}
              />
            </section>
          ) : null }
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('addReports.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('addReports.submit')}
            isLoading={editReportMutation.isLoading || postReportMutation.isLoading}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('addReports.back_to_reports')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddFillingReport;
