import React from 'react';
import PropTypes from 'prop-types';
// Components
import Input from '../../../atoms/Input/Input';

function NumberDate({
  label, value, onChange, brotherNumber, brotherDate,
}) {
  return (
    <div className="inputForm">
      <div className="inputForm_control inputForm_label">
        {label}
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          type="number"
          labelHidden
          value={value.indexValue}
          onChange={(e) => onChange({ ...value, indexValue: e.target.value })}
          required={!!value.isDirty}
        />
      </div>
      {brotherNumber || null}
      <div className="inputForm_control inputForm_date">
        <Input
          type="date"
          labelHidden
          value={value.indexDate}
          onChange={(e) => onChange({ ...value, indexDate: e.target.value })}
          required={!!value.isDirty}
          min="2000-01-01"
          max="2099-12-31"
        />
      </div>
      {brotherDate || null}
    </div>
  );
}

NumberDate.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.objectOf({
    indexValue: PropTypes.number,
    indexDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  brotherNumber: PropTypes.element,
  brotherDate: PropTypes.element,
};

NumberDate.defaultProps = {
  brotherNumber: null,
  brotherDate: null,
};

export default NumberDate;
