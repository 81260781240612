import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import Picto from '../../components/atoms/Picto/Picto';
import Layout from '../../components/template/Layout';
import Card from '../../components/atoms/Card/Card';

// styles
import styles from './DocumentDeposit.module.css';

// utils & misc
import cn from '../../utils/cn';

function DocumentDeposit() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Layout title="Dashboard">
      {/* Dépot document */}
      <Card className={styles.topTitle}>
        <Picto className={styles.topPicto} name="documentaire" />
        <h1 className={cn(['title no-margin', styles.title])}>{t('dashboard.security_document_depository')}</h1>
        <Picto className={styles.topPicto} name="documentaire" />
      </Card>
      <section className={styles.gridDocs}>
        <Card className={styles.card} onClick={() => navigate('/somewhere')}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.contract')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
        <Card className={styles.card} onClick={() => navigate('/somewhere')}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.security_document_depository')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
        <Card className={styles.card} onClick={() => navigate('/somewhere')}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.sanitary_document_depository')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
        <Card className={styles.card} onClick={() => navigate('/somewhere')}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.regulation')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
      </section>
      {/* documents sanitaire */}
      <Card className={styles.topTitle}>
        <Picto className={styles.topPicto} name="documentaire" />
        <p className={cn(['title no-margin', styles.title])}>{t('dashboard.sanitary_document_depository')}</p>
        <Picto className={styles.topPicto} name="documentaire" />
      </Card>
      <section className={styles.gridDocs}>
        <Card className={styles.card} onClick={() => navigate('/somewhere')}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.contract')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
        <Card className={styles.card} onClick={() => navigate('/somewhere')}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.general_information')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
        <Card className={styles.card} onClick={() => navigate('/somewhere')}>
          <div>
            <p className={cn([styles.title, styles.titleNumber])}>{t('dashboard.regulation')}</p>
          </div>
          <div>
            <Picto className={styles.pictoCard} name="documentaire" />
          </div>
        </Card>
      </section>
    </Layout>
  );
}

export default DocumentDeposit;
