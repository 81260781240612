/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Components
import Radio from '../../../atoms/Radio/Radio';
import Input from '../../../atoms/Input/Input';

function RadioNumberTime({
  label, value, onChange, id,
}) {
  // Hooks
  const { t } = useTranslation();

  const currentDate = new Date();
  const currentHours = (`0${currentDate.getHours()}`).slice(-2);
  const currentMinutes = (`0${currentDate.getMinutes()}`).slice(-2);
  const currentTime = `${currentHours}:${currentMinutes}`;

  return (
    <div className="inputForm">
      <div className="inputForm_control inputForm_label">
        {label}
      </div>
      <div className="inputForm_control inputForm_radioGroup">
        <div className="inputForm_radio">
          <Radio
            id={`PM30S-${value.id}`}
            name={`PLM30S-${value.id}`}
            label={t('inputs.more_30_seconds')}
            value={false}
            checked={value.lessThan30s === false}
            onChange={() => onChange({ ...value, lessThan30s: false })}
            required={!!value.isDirty}
          />
        </div>
        <div className="inputForm_radio">
          <Radio
            id={`PL30S-${value.id}`}
            name={`PLM30S-${value.id}`}
            label={t('inputs.less_30_seconds')}
            value
            checked={value.lessThan30s === true}
            onChange={() => onChange({ ...value, lessThan30s: true })}
          />
        </div>
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={`PL30S-number-${id}`}
          name={`PL30S-number-${id}`}
          type="number"
          labelHidden
          value={value.stabilizationDegree}
          step="0.1"
          onChange={(e) => onChange({ ...value, stabilizationDegree: e.target.value })}
          required={!!value.isDirty}
        />
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={`PL30S-time-${id}`}
          name={`PL30S-time-${id}`}
          type="time"
          labelHidden
          value={value.time || (value?.stabRecordDate !== null && value?.stabRecordDate.substring(11, 16)) || currentTime}
          onChange={(e) => onChange({ ...value, time: e.target.value })}
          required={!!value.isDirty}
        />
      </div>
    </div>
  );
}

RadioNumberTime.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.objectOf({ radio: PropTypes.bool, number: PropTypes.number, time: PropTypes.string }).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default RadioNumberTime;
