import api from './_api';

/**
 * GET - Get Users list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getUsersList = (queryParams) => api.get('/users', { params: queryParams });

/**
 * GET - Get User informations
 * @returns {Promise<AxiosResponse>}
 */
export const getUserMe = () => api.get('/users/me');

/**
 * GET - Get User
 * @param {Number} userId
 * @returns {Promise<AxiosResponse>}
 */
export const getUser = (userId) => api.get(`/users/${userId}`);

/**
 * POST - Create User
 * @param {{
 * role:String,
 * firstName:String,
 * lastName:String,
 * email:String,
 * address:String,
 * postalCode:Number,
 * city:String,
 * phone1:Number,
 * phone2:Number,
 * company:String,
 * job:String,
 * enabled:Boolean
 * }} userToCreate
 * @returns {Promise<AxiosResponse>}
 */
export const postUser = (userToCreate) => api.post('/users', userToCreate);

/**
 * PUT - Edit User
 * @param {{
 * id:Number,
 * role:String,
 * firstName:String,
 * lastName:String,
 * email:String,
 * address:String,
 * postalCode:Number,
 * city:String,
 * phone1:Number,
 * phone2:Number,
 * company:String,
 * job:String,
 * enabled:Boolean
 * }} userToEdit
 * @returns {Promise<AxiosResponse>}
 */
export const editUser = (userToEdit) => api.put(`/users/${userToEdit.id}`, userToEdit);

/**
 * DEL - Delete User
 * @param {Number} userId
 * @returns {Promise<AxiosResponse>}
 */
export const deleteUser = (userId) => api.delete(`/users/${userId}`);
