import { omit } from 'lodash';

export default function appReducer(state, action) {
  switch (action.type) {
    // AUTH REDUCERS
    case 'SET_USER': {
      return { ...state, user: action.payload };
    }

    case 'SET_USER_IMPERSONATED': {
      return { ...state, userImpersonated: action.payload };
    }

    case 'CLEAR_USER_IMPERSONATED': {
      return omit(state, 'userImpersonated');
    }

    case 'CHOICE_BOOKLET': {
      return { ...state, choiceBooklet: action.payload };
    }
    case 'CHOICE_ESTABLISHMENT': {
      return { ...state, choiceEstablishment: action.payload };
    }
    case 'SET_IS_STAY_LOGIN_CHECK': {
      return { ...state, isStayLoginCheck: action.payload };
    }
    case 'CLEAR_CONTEXT': {
      localStorage.clear();
      sessionStorage.clear();
      return {};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
