/**
 * Method to download a file
 * @param {Blob} blob
 * @param {string} fileName
 * @param {string} type - default: application/pdf
 */
const downloadFile = (blob, fileName, type = 'application/pdf') => {
  const data = URL.createObjectURL(blob, { type });
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(data);
  link.remove();
};

export default downloadFile;
