import React from 'react';
import PropTypes from 'prop-types';
// Components
import Input from '../../../atoms/Input/Input';

function NumberTime({
  label, value, onChange, id,
}) {
  const currentDate = new Date();
  const currentHours = (`0${currentDate.getHours()}`).slice(-2);
  const currentMinutes = (`0${currentDate.getMinutes()}`).slice(-2);
  const currentTime = `${currentHours}:${currentMinutes}`;

  return (
    <div className="inputForm">
      <div className="inputForm_control inputForm_label">
        {label}
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={`number-${id}`}
          name={`number-${id}`}
          label={label}
          labelHidden
          type="number"
          value={value.degreeValue}
          onChange={(e) => onChange({ ...value, degreeValue: e.target.value })}
          step="0.1"
          required={!!value.isDirty}
        />
      </div>
      <div className="inputForm_control inputForm_input">
        <Input
          id={`time-${id}`}
          name={`time-${id}`}
          type="time"
          value={value.time || (value?.recordDate !== null && value?.recordDate.substring(11, 16)) || currentTime}
          onChange={(e) => onChange({ ...value, time: e.target.value })}
          label={label}
          labelHidden
          required={!!value.isDirty}
        />
      </div>
    </div>
  );
}

NumberTime.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.objectOf({
    degreeValue: PropTypes.number,
    time: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default NumberTime;
