/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

axiosClient.interceptors.request.use((config) => {
  const authStringified = localStorage.getItem('auth');
  const auth = authStringified ? JSON.parse(authStringified) : null;
  const contextStringified = sessionStorage.getItem('context') || localStorage.getItem('context');
  const context = contextStringified ? JSON.parse(contextStringified) : null;
  const newConfig = { ...config };
  newConfig.headers.Authorization = auth ? `Bearer ${auth.token}` : null;
  if (context?.userImpersonated) {
    newConfig.headers['X-Switch-User'] = context.userImpersonated.email;
  }

  return newConfig;
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { message } = error.response.data || {};
    const originalRequest = error.config;

    // return early because we wont handle common errors in this interceptor
    if (message !== 'Expired JWT Token' || originalRequest._retry) {
      return Promise.reject(error.response);
    }

    originalRequest._retry = true;
    try {
      const authStringified = localStorage.getItem('auth');
      const auth = authStringified ? JSON.parse(authStringified) : null;
      const refreshTokenRes = await axiosClient.post('/token/refresh', { refresh_token: auth.refresh_token });
      localStorage.setItem('auth', JSON.stringify(refreshTokenRes?.data));
      originalRequest.headers.Authorization = `Bearer ${refreshTokenRes?.data?.token}`;
      // retry the failed call with the new config
      return axios.request(originalRequest);
    } catch {
      return Promise.reject(error.response);
    }
  },
);

export default axiosClient;
