/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { sortBy, omit } from 'lodash';
import * as Yup from 'yup';
// Components
import Layout from '../../components/template/Layout';
import Input from '../../components/atoms/Input/Input';
import Checkbox from '../../components/atoms/Checkbox/Checkbox';
import Radio from '../../components/atoms/Radio/Radio';
import Button from '../../components/atoms/Button/Button';
// Services
import {
  getEquipment,
  postEquipmentInformations,
  editEquipmentInformations,
} from '../../services/equipments';
import { getEquipmentFamiliesList } from '../../services/equipmentFamilies';
import { getControlsList } from '../../services/controls';

const initialValues = {
  name: '',
  equipmentFamilyId: '',
  bookletIds: [],
  controlIds: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('global.required_field'),
  equipmentFamilyId: Yup.string().required('global.required_field'),
  bookletIds: Yup.array(),
  controlIds: Yup.array(),
});

function AddEquipment() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  // States
  const [equipmentFamilies, setEquipmentFamilies] = useState([]);
  const [controls, setControls] = useState([]);
  // API calls
  const getEquipmentFamiliesListQuery = useQuery('equipment-families', () => getEquipmentFamiliesList(), {
    onSuccess: (data) => {
      setEquipmentFamilies(data.data.equipmentFamilies);
    },
  });
  const getControlsListQuery = useQuery('controls', () => getControlsList(), {
    onSuccess: (data) => {
      setControls(data.data.controls);
    },
  });
  const postEquipmentInformationsMutation = useMutation(postEquipmentInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_equipment.equipment_added'));
    },
  });
  const editEquipmentInformationsMutation = useMutation(editEquipmentInformations, {
    onSuccess: () => {
      navigate(-1);
      toast.success(t('add_equipment.equipment_edited'));
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formValues) => {
      if (urlParams && urlParams.action === 'edit') {
        editEquipmentInformationsMutation.mutate(formValues);
      } else {
        postEquipmentInformationsMutation.mutate(formValues);
      }
    },
  });

  const getEquipmentQuery = useQuery('equipment', () => getEquipment(urlParams.id), {
    enabled: !!urlParams?.id,
    onSuccess: (data) => {
      formik.setValues({ ...omit(data.data, 'equipmentFamily'), equipmentFamilyId: data?.data?.equipmentFamily?.id });
    },
  });

  const handleCheckboxClick = (fieldName, fieldValue, isChecked) => formik.setFieldValue(
    fieldName,
    isChecked
      ? formik.values[fieldName].concat(fieldValue)
      : formik.values[fieldName].filter((id) => id !== fieldValue),
  );

  return (
    <Layout
      title={t('add_equipment.title')}
      queryError={
        postEquipmentInformationsMutation.error
        || editEquipmentInformationsMutation.error
        || getEquipmentFamiliesListQuery.error
        || getControlsListQuery.error
        || getEquipmentQuery.error
      }
    >
      <header className="header">
        <h1 className="title">
          {urlParams && urlParams.action === 'edit' ? t('add_equipment.edit_title') : t('add_equipment.title')}
        </h1>
      </header>
      <form className="form shadow-sm" onSubmit={formik.handleSubmit}>
        <div className="form_group">
          <Input
            id="name"
            type="text"
            name="name"
            label={t('add_equipment.name')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name ? (
            <div className="error">
              {t(formik.errors.name)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_equipment.booklets')}</div>
          <div>
            <Checkbox
              id="bookletSecurity"
              name="bookletIds[]"
              label={t('add_equipment.booklet_security')}
              onChange={(event) => handleCheckboxClick('bookletIds', 2, event.target.checked)}
              onBlur={formik.handleBlur}
              checked={formik.values.bookletIds.includes(2)}
              value={2}
            />
          </div>
          <div>
            <Checkbox
              id="bookletSanitary"
              name="bookletIds[]"
              label={t('add_equipment.booklet_sanitary')}
              onChange={(event) => handleCheckboxClick('bookletIds', 1, event.target.checked)}
              onBlur={formik.handleBlur}
              checked={formik.values.bookletIds.includes(1)}
              value={1}
            />
          </div>
          {formik.errors.bookletIds && formik.touched.bookletIds ? (
            <div className="error">
              {formik.errors.bookletIds}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_equipment.families')}</div>
          {sortBy(equipmentFamilies, ['name']).map((family) => (
            <div>
              <Radio
                id={`family-${family.id}`}
                name="equipmentFamilyId"
                label={family.name}
                onChange={(event) => formik.setFieldValue('equipmentFamilyId', parseInt(event.target.value, 10))}
                onBlur={formik.handleBlur}
                checked={formik.values.equipmentFamilyId === family.id}
                value={family.id}
              />
            </div>
          ))}
          {formik.errors.equipmentFamilyId && formik.touched.equipmentFamilyId ? (
            <div className="error">
              {t(formik.errors.equipmentFamilyId)}
            </div>
          ) : null }
        </div>
        <div className="form_group">
          <div className="label">{t('add_equipment.controls')}</div>
          {sortBy(controls, ['name']).map((control) => (
            <div>
              <Checkbox
                id={`control-${control.id}`}
                name="controlIds[]"
                label={control.name}
                onChange={(event) => handleCheckboxClick('controlIds', control.id, event.target.checked)}
                onBlur={formik.handleBlur}
                checked={formik.values.controlIds?.includes(control.id)}
                value={control.id}
              />
            </div>
          ))}
          {formik.errors.controlIds && formik.touched.controlIds ? (
            <div className="error">
              {formik.errors.controlIds}
            </div>
          ) : null }
        </div>
        <div className="form_footer">
          <div className="form_infos">
            <small>{t('add_equipment.mandatory_fields')}</small>
          </div>
          <Button
            type="submit"
            className="form_submit"
            label={t('add_equipment.submit')}
            isLoading={postEquipmentInformationsMutation.isLoading}
          />
        </div>
      </form>
      <footer className="footer">
        <button type="button" className="link" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>{t('add_equipment.back_to_equipment')}</span>
        </button>
      </footer>
    </Layout>
  );
}

export default AddEquipment;
