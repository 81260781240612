/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import {
  faEye,
} from '@fortawesome/free-solid-svg-icons';

// Components
import Table from '../../molecules/Table/Table';

// services
import { getUsersList } from '../../../services/users';

// utils & misc
import { USERS_ROLES } from '../../../utils/constant';

function UsersList({ establishmentId }) {
  const { t } = useTranslation();

  // API Calls
  const getUsersListQuery = useQuery(
    ['usersList'],
    () => getUsersList({ structureId: establishmentId }),
  );

  const columns = useMemo(
    () => [
      {
        Header: `${t('users.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('users.email')}`,
        accessor: 'email',
      },
      {
        Header: `${t('users.role')}`,
        accessor: 'role',
        Cell: ({ row: { original: { role } } }) => (
          `${role === USERS_ROLES.SUPER_ADMIN ? t('add_user.super_admin') : ''} 
          ${role === USERS_ROLES.ADMIN ? t('add_user.admin') : ''} 
          ${role === USERS_ROLES.USER ? t('add_user.user') : ''}`
        ),
      },
      {
        Header: `${t('users.lastname')}`,
        accessor: 'lastName',
      },
      {
        Header: `${t('users.firstname')}`,
        accessor: 'firstName',
      },
      {
        Header: `${t('users.company')}`,
        accessor: 'company',
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/users/edit/${id}`}
              title={t('users.edit')}
            >
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <div>
      <Table
        columns={columns}
        isLoading={getUsersListQuery.isLoading}
        data={getUsersListQuery?.data?.data?.users || []}
      />
    </div>
  );
}

UsersList.propTypes = {
  establishmentId: PropTypes.number,
};
UsersList.defaultProps = {
  establishmentId: null,
};

export default UsersList;
