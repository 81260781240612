import api from './_api';

/**
 * GET - Get Societies list
 * @param {Number} pageId
 * @returns {Promise<AxiosResponse>}
 */
export const getSocietiesList = (queryParams) => api.get('/societies', { params: queryParams });

/**
 * GET - Get Society
 * @param {Number} societyId
 * @returns {Promise<AxiosResponse>}
 */
export const getSociety = (societyId) => api.get(`/societies/${societyId}`);
