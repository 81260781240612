/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './Card.module.css';
import useAppContext from '../../../store/useAppContext';
import cn from '../../../utils/cn';

function Card({
  borderTopColored, children, className, onClick,
}) {
  const [context] = useAppContext();
  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={cn([styles.card,
          'shadow-md',
          className,
          borderTopColored && (context.choiceBooklet === 2 ? styles.security : styles.sanitary)])}
      >
        {children}
      </button>
    );
  }
  return (
    <div
      className={cn([styles.card,
        'shadow-md',
        className,
        borderTopColored && (context.choiceBooklet === 2 ? styles.security : styles.sanitary)])}
    >
      {children}
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  borderTopColored: PropTypes.bool,
  onClick: PropTypes.func,
};
Card.defaultProps = {
  className: '',
  borderTopColored: false,
  onClick: undefined,
};

export default Card;
