/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faTrashCan, faChevronLeft, faChevronRight, faAddressCard,
} from '@fortawesome/free-solid-svg-icons';

// Components
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
import Toggle from '../../components/atoms/Toggle/Toggle';

// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';

// Services
import { getInputsHistory, deleteInput } from '../../services/inputs';
import { getUsersList } from '../../services/users';
import { getBuildingsList } from '../../services/structures';

// Styles
import styles from './HistoricInputs.module.css';

// Utils
import { formatDateToUser } from '../../utils/dates';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '14.375rem',
    padding: '2px',
    border: '2px solid #dcdee8',
    borderRadius: '1.875rem',
  }),
};

// TO DO : REFACTO TO MAKE IT GENERIC TO ALL OTHERS MODALS
const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: '80vw',
    minWidth: '1024px',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function HistoricInputs() {
  const [openModal, setOpenModal] = useState(false);
  const [inputToDelete, setInputToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  const [building, setBuilding] = useState(null);
  const [completed, setCompleted] = useState(undefined);
  const [checked, setChecked] = useState(false);

  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [context] = useAppContext();

  // API Calls
  const getHistoricInputsListQuery = useQuery(
    ['historicInputs', page, search, context.choiceBooklet, completed, building],
    () => getInputsHistory({
      page,
      search,
      completed,
      bookletId: context.choiceBooklet,
      establishmentId: context.choiceEstablishment.id,
      buildingIds: building,
    }),
  );

  const getBuildingsListQuery = useQuery(['buildings'], () => getBuildingsList({
    establishmentId: context.choiceEstablishment.id,
    bookletId: context.choiceBooklet,
  }));
  const getUsersListQuery = useQuery(['usersList'], () => getUsersList({
    structureId: context.choiceEstablishment.id,
  }));

  const buildingsMapped = getBuildingsListQuery?.data?.data?.buildings?.map((buildingIte) => ({
    label: buildingIte?.name,
    value: buildingIte?.id,
  }));

  const handleModalToDelete = (selectedEquipmentToDelete) => {
    setOpenModal(true);
    setInputToDelete(selectedEquipmentToDelete);
  };

  const deleteInputMutation = useMutation(deleteInput, {
    onSuccess:
      () => {
        getHistoricInputsListQuery.refetch();
      },
  });

  const handleDelete = () => {
    deleteInputMutation.mutate(inputToDelete.id);
    setOpenModal(false);
    toast.success(t('add_establishment.deleted'));
  };

  const handleBuilding = (buildings) => {
    const buildingIds = buildings.map((buildingIte) => buildingIte.value);
    setBuilding(buildingIds);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const findUser = (userId) => {
    const a = getUsersListQuery?.data?.data?.users
      ?.map((user) => ({ ...user, name: `${user.firstName} ${user.lastName}` }))
      ?.find((user) => user.id === userId);
    return a;
  };

  const columns = useMemo(
    () => [
      {
        Header: `${t('inputs_historic.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('inputs_historic.initial_control_date')}`,
        accessor: 'inputDate',
        Cell: ({ row: { original: { inputDate } } }) => (
          <div>
            {formatDateToUser(inputDate)}
          </div>
        ),
      },
      {
        Header: `${t('inputs_historic.name')}`,
        accessor: 'title',
      },
      {
        Header: `${t('inputs_historic.state')}`,
        Cell: ({ row: { original } }) => (
          <div>
            {original.completed ? t('inputs_historic.inputed') : t('inputs_historic.not_inputed')}
          </div>
        ),
      },
      {
        Header: `${t('inputs_historic.comment')}`,
        accessor: 'comment',
        // eslint-disable-next-line no-shadow
        Cell: ({ row: { original: { inputType, comment, checked } } }) => (
          <div>
            {inputType === 'OKNEKO_COMM_FACULT'
              || inputType === 'OKNEKO_COMM_OBLIG_SI_KO'
              || inputType === 'OKNEKO_COMM_OBLIG_SI_NE'
              || inputType === 'OKNEKO_COMM_OBLIG_TTLT'
              || inputType === 'OKNEKO_Seul' ? (
                <>
                  {checked === false ? (
                    <>
                      <span className="tag--error">KO</span>
                      <span>{comment !== null ? ` | ${comment}` : null}</span>
                    </>
                  ) : null}
                  {checked === null ? <span>{comment !== null ? comment : null}</span> : null}
                  {checked === true ? (
                    <>
                      <span className="tag--success">OK</span>
                      <span>{comment !== null ? ` | ${comment}` : null}</span>
                    </>
                  ) : null}
                </>
              ) : comment}
            {' '}
          </div>
        ),
      },
      {
        Header: t('interventions.user'),
        accessor: 'userInChargeId',
        Cell: ({ row: { original: { id, userInChargeId } } }) => (
          <div className={styles.user}>
            <FontAwesomeIcon data-tip data-for={`userColResult${id}`} icon={faAddressCard} />
            <ReactTooltip id={`userColResult${id}`} place="top" type="info" effect="solid">
              {findUser(userInChargeId)?.name ?? '-'}
            </ReactTooltip>
          </div>
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/saisies/historique/edit/${id}`}
              title={t('inputs_historic.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button
              type="button"
              className="action suppr"
              title={t('inputs_historic.suppr')}
              onClick={() => handleModalToDelete({ id })}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </div>
        ),
      },
    ],
    [],
  );

  const handleChange = () => {
    if (completed === undefined) {
      setCompleted(false);
      setChecked(true);
    }
    if (completed === false) {
      setCompleted(undefined);
      setChecked(false);
    }
  };

  return (
    <Layout
      title={t('inputs_historic.title_page')}
      layout="table"
      queryError={
        getHistoricInputsListQuery.error
        || getUsersListQuery.error
        || getBuildingsListQuery.error
      }
    >
      <header className="header">
        <div className="row mb-20">
          <Link to="/saisies" className="link">
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>{t('inputs_historic.back_to_inputs')}</span>
          </Link>
        </div>
        <div className="row mb-20">
          <h1 className="title">{t('inputs_historic.title_page')}</h1>
          <Search className="mb-20" onChange={handleSearch} />
        </div>
        <div className="row">
          <div className={styles.filter}>
            <label className={styles.filterLabel} htmlFor="buildings">
              {t('inputs_historic.buildings')}
            </label>
            <Select
              styles={customStyles}
              id="buildings"
              options={buildingsMapped}
              placeholder={t('global.choose')}
              noOptionsMessage={() => t('global.no_options_available')}
              onChange={handleBuilding}
              isMulti
            />
          </div>
          <Toggle
            id="to-complete"
            label={t('inputs_historic.not_inputed')}
            checked={checked}
            value={completed}
            onChange={handleChange}
          />
        </div>
      </header>
      {
        getHistoricInputsListQuery.isLoading
        || getUsersListQuery.isLoading
        || getBuildingsListQuery.isLoading ? (
          <div className="loader" />
          ) : (
            <div>
              <div className={styles.table}>
                <Table
                  columns={columns}
                  isLoading={getHistoricInputsListQuery.isLoading}
                  data={getHistoricInputsListQuery?.data?.data?.inputs || []}
                  hasSort
                  pagination={(
                    <Pagination
                      previousLabel={(
                        <FontAwesomeIcon icon={faChevronLeft} />
                )}
                      nextLabel={(
                        <FontAwesomeIcon icon={faChevronRight} />
                )}
                      onPageChange={onPageChange}
                      totalCount={getHistoricInputsListQuery?.data?.data?.total || 0}
                      currentPage={page}
                      pageSize={10}
                      numberElementsDisplayed={getHistoricInputsListQuery?.data?.data?.inputs?.length || 0}
                    />
            )}
                />
              </div>
              <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                style={customModalStyles}
              >
                <p className="modal_paragraph">{t('global.modal_content')}</p>
                <div className="modal_actions">
                  <button
                    type="button"
                    className="modal_button"
                    onClick={() => setOpenModal(false)}
                  >
                    {t('global.modal_dismiss')}
                  </button>
                  <button
                    type="button"
                    className="modal_button modal_button--success"
                    onClick={handleDelete}
                  >
                    {t('global.modal_validate')}
                  </button>
                </div>
              </Modal>
            </div>
          )
      }
    </Layout>
  );
}

export default HistoricInputs;
