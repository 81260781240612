/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  useTable, useFilters, usePagination, useSortBy,
} from 'react-table';
// Utils
import styles from './Table.module.css';
import cn from '../../../utils/cn';

function Table({
  columns, data, pagination, isLoading, hasSort, noResultsLabel,
}) {
  // Hooks
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable({
    columns,
    data,
    manualPagination: true,
    autoResetPage: false,
  }, useFilters, useSortBy, usePagination);

  const sort = (column) => {
    const columnContent = column.render('Header');
    if (!columnContent.trim().length || !hasSort) return '';
    if (column.isSorted) {
      if (column.isSortedDesc) return ' ↓';
      return ' ↑';
    }
    return ' ↕';
  };

  return (
    <>
      <div className={cn([styles.wrapper, 'shadow-sm'])}>
        <table className={styles.table} {...getTableProps()}>
          <thead className={styles.tableHeader}>
            {headerGroups.map((headerGroup) => (
              <tr className={styles.row} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={cn([styles.cell, styles.cellHeader])}
                    {...column.getHeaderProps(
                      hasSort ? column.getSortByToggleProps() : undefined,
                    )}
                  >
                    {column.render('Header')}
                    {sort(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.tableBody} {...getTableBodyProps()}>
            {isLoading ? (
              <tr className={styles.row}>
                <td className={styles.cell}>
                  <div className="loader" />
                </td>
              </tr>
            )
              : (page.length > 0 && page.map((row) => {
                prepareRow(row);
                return (
                  <tr className={styles.row} {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td className={styles.cell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })) || (
              <tr className={styles.row}>
                <td className={styles.cell}>
                  <div className={styles.noResults}>{noResultsLabel || t('global.no_results')}</div>
                </td>
              </tr>
              )}
          </tbody>
        </table>
      </div>
      {pagination ? (
        <div className={styles.pagination}>
          {pagination}
        </div>
      ) : null}
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.node,
  isLoading: PropTypes.bool,
  hasSort: PropTypes.bool,
  noResultsLabel: PropTypes.string,
};

Table.defaultProps = {
  pagination: null,
  isLoading: false,
  hasSort: false,
  noResultsLabel: '',
};

export default Table;
