/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faDownload, faEdit, faTrashCan, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Components
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
import useAppContext from '../../store/useAppContext';
// Services
import { getTrainingsList, getTrainingFileToDownload, deleteTraining } from '../../services/trainings';
// Utils
import styles from './Trainings.module.css';
import { TRAINING_TYPES, TRAINING_NAMES } from '../../utils/constant';
import downloadFile from '../../utils/downloadFile';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Trainings() {
  // States
  const [openModal, setOpenModal] = useState(false);
  const [trainingToDelete, setTrainingToDelete] = useState({
    id: null,
  });
  const [search, setSearch] = useState('');
  // Hooks
  const { t } = useTranslation();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const [context] = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (context.choiceBooklet === 1) navigate('/dashboard');
  });

  // API Calls
  const getTrainingsListQuery = useQuery(['trainings', page, search], () => getTrainingsList({
    page,
    search,
    establishmentId: context?.choiceEstablishment?.id,
  }));
  const getTrainingDownload = useMutation((mutationParams) => getTrainingFileToDownload(mutationParams), {
    onSuccess: (response, mutationParams) => {
      downloadFile(
        response?.data,
        getTrainingsListQuery?.data?.data?.trainings
          .find((training) => training.id === mutationParams)?.documentFileName,
      );
    },
  });
  const deleteTrainingMutation = useMutation(deleteTraining, {
    onSuccess:
      () => {
        getTrainingsListQuery.refetch();
      },
  });

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const handleModal = (selectedTrainingToDelete) => {
    setOpenModal(true);
    setTrainingToDelete(selectedTrainingToDelete);
  };

  const handleDelete = () => {
    setOpenModal(false);
    deleteTrainingMutation.mutate(trainingToDelete.id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('trainings.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('trainings.date')}`,
        accessor: 'date',
        Cell: ({ row: { original: { date } } }) => (
          format(new Date(date), 'dd/MM/yyyy')
        ),
      },
      {
        Header: `${t('trainings.company')}`,
        accessor: 'speakerName',
        Cell: ({
          row: {
            original: {
              type, speakerName, firstName, lastName,
            },
          },
        }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? speakerName || t('add_training.house_keeping')
            : `${firstName} ${lastName}`}`
        ),
      },
      {
        Header: `${t('trainings.type')}`,
        accessor: 'type',
        Cell: ({ row: { original: { type } } }) => (
          `${type === TRAINING_TYPES.FIRE_SAFETY_PREVENTION
            ? t('add_training.fire_safety_prevention')
            : t('add_training.maintenance_manager')}`
        ),
      },
      {
        Header: `${t('trainings.name')}`,
        accessor: 'name',
        Cell: ({ row: { original: { name } } }) => (
          `${name === TRAINING_NAMES.HANDLING_EMERGENCY_MEANS ? t('add_training.handling_emergency_means') : ''}
          ${name === TRAINING_NAMES.EVACUATION ? t('add_training.evacuation') : ''}
          ${name === TRAINING_NAMES.DESIGNATED_EMPLOYEES_EVACUATION
            ? t('add_training.designated_employees_evacuation')
            : ''
          }
          ${name === TRAINING_NAMES.UNANNOUNCED_EXERCISE ? t('add_training.unannounced_exercise') : ''}
          ${name === TRAINING_NAMES.SSI ? t('add_training.ssi') : ''}
          ${name === TRAINING_NAMES.INTERNAL ? t('add_training.internal') : ''}
          ${name === TRAINING_NAMES.SSIAP ? t('add_training.ssiap') : ''}
          ${name === TRAINING_NAMES.SST ? t('add_training.sst') : ''}
          ${name === TRAINING_NAMES.ELECTRICAL_CLEARANCE ? t('add_training.electrical_clearance') : ''}`
        ),
      },
      {
        Header: `${t('trainings.headcount')}`,
        accessor: 'headcount',
      },
      {
        Header: `${t('trainings.person_in_charge')}`,
        accessor: 'personInCharge',
      },
      {
        Header: `${t('trainings.document')}`,
        accessor: 'documentFile',
        Cell: ({ row: { original: { documentFile, id } } }) => (
          documentFile !== null && (
            <Button
              type="button"
              className="action"
              label={<FontAwesomeIcon icon={faDownload} />}
              title={t('trainings.download')}
              onClick={() => getTrainingDownload.mutate(id)}
            />
          )
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id } } }) => (
          <div className="actions">
            <Link
              className="action"
              to={`/formations/edit/${id}`}
              title={t('trainings.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <Button
              type="button"
              className="action suppr"
              label={<FontAwesomeIcon icon={faTrashCan} />}
              title={t('trainings.suppr')}
              onClick={() => handleModal({ id })}
            />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('trainings.title')}
        queryError={getTrainingsListQuery.error || getTrainingDownload.error || deleteTrainingMutation.error}
        layout="table"
      >
        <header className="header">
          <div className="row">
            <h1 className="title">{t('trainings.title')}</h1>
            <Link className="add" to="/formations/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('trainings.add')}</span>
            </Link>
          </div>
          <div className="row">
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            isLoading={getTrainingsListQuery.isLoading}
            data={getTrainingsListQuery?.data?.data?.trainings || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getTrainingsListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
                numberElementsDisplayed={getTrainingsListQuery?.data?.data?.trainings?.length || 0}
              />
            )}
          />
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default Trainings;
