import api from './_api';

/**
 * GET - Get Inputs to complete
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getInputsToComplete = (queryParams) => api.get('/inputs', { params: queryParams });

/**
 * GET - Get Inputs history
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getInputsHistory = (queryParams) => api.get('/inputs/history', { params: queryParams });

/**
 * PUT - PUT Input
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const putInput = (input) => api.put(`/inputs/${input.id}`, input);

/**
 * POST - Post Inputs
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const postInputs = (inputs) => api.post('/inputs', inputs);

/**
 * DELETE - DELETE Input
 * @param {id: Number} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const deleteInput = (id) => api.delete(`/inputs/${id}`);

/**
 * DELETE - DELETE Input
 * @param {id: Number} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getInput = (id) => api.get(`/inputs/${id}`);

/**
 * GET - Get lightning equipments
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const getLightningEquipments = (queryParams) => api.get('/safety-lighting-equipment-inputs', {
  params: queryParams,
});

/**
 * POST - Post Inputs
 * @param {Object} queryParams
 * @returns {Promise<AxiosResponse>}
 */
export const postLightingEquipment = (inputs) => api.post('/safety-lighting-equipment-inputs', inputs);
