/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { debounce, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faCircleCheck, faCircleXmark, /* faSignIn, */ faEdit, faTrashCan, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
// Hooks
import useAppContext from '../../store/useAppContext';
// Components
import Layout from '../../components/template/Layout';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';
import Pagination from '../../components/molecules/Pagination';
import Search from '../../components/molecules/Search/Search';
// Hooks
import useTableFiltersPagination from '../../hooks/useTableFiltersPagination';
// Services
import { getUsersList, getUserMe, deleteUser } from '../../services/users';
// Utils
import styles from './Users.module.css';
import { USERS_ROLES } from '../../utils/constant';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
  },
};

function Users() {
  // States
  const [openModal, setOpenModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState({
    id: null,
  });

  const [search, setSearch] = useState('');
  // Hooks
  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();
  const { page, onPageChange } = useTableFiltersPagination({ initialFilters: {} });
  const navigate = useNavigate();
  // API Calls
  const getUsersListQuery = useQuery(['users', page, search], () => getUsersList({ page, search }));
  const getUserMeQuery = useQuery('get-user-me', getUserMe, {
    enabled: !isEmpty(context?.userImpersonated),
    onSuccess: (response) => {
      dispatch({ type: 'SET_USER_IMPERSONATED', payload: response?.data });
      navigate('/dashboard');
    },
  });
  const deleteUserMutation = useMutation(deleteUser, {
    onSuccess:
      () => {
        getUsersListQuery.refetch();
      },
  });

  const handleModal = (selectedUserToDelete) => {
    setOpenModal(true);
    setUserToDelete(selectedUserToDelete);
  };

  const handleSearch = debounce((e) => {
    onPageChange(1);
    setSearch(e.target.value.toLowerCase());
  }, 500);

  const handleDelete = () => {
    setOpenModal(false);
    deleteUserMutation.mutate(userToDelete.id);
  };

  /* const handleSwitchUser = (email) => {
    dispatch({ type: 'SET_USER_IMPERSONATED', payload: { email } });
  }; */

  const columns = React.useMemo(
    () => [
      {
        Header: `${t('users.id')}`,
        accessor: 'id',
      },
      {
        Header: `${t('users.email')}`,
        accessor: 'email',
      },
      {
        Header: `${t('users.role')}`,
        accessor: 'role',
        Cell: ({ row: { original: { role } } }) => (
          `${role === USERS_ROLES.SUPER_ADMIN ? t('add_user.super_admin') : ''} 
          ${role === USERS_ROLES.ADMIN ? t('add_user.admin') : ''} 
          ${role === USERS_ROLES.USER ? t('add_user.user') : ''}`
        ),
      },
      {
        Header: `${t('users.lastname')}`,
        accessor: 'lastName',
      },
      {
        Header: `${t('users.firstname')}`,
        accessor: 'firstName',
      },
      {
        Header: `${t('users.company')}`,
        accessor: 'company',
      },
      {
        Header: `${t('users.enabled')}`,
        accessor: 'enabled',
        Cell: ({ row: { original: { enabled } } }) => (
          enabled
            ? (
              <span className="tag tag--transparent tag--success">
                <FontAwesomeIcon icon={faCircleCheck} />
                {' '}
                {t('global.yes')}
              </span>
            )
            : (
              <span className="tag tag--transparent tag--error">
                <FontAwesomeIcon icon={faCircleXmark} />
                {' '}
                {t('global.no')}
              </span>
            )
        ),
      },
      {
        Header: ' ',
        Cell: ({ row: { original: { id/* , email */ } } }) => (
          <div className="actions">
            {/* TODO: à remettre quand l'US concernée sera terminée
            {![context?.user?.id, context?.userImpersonated?.id].includes(id) ? (
              <Button
                type="button"
                className="action"
                title={t('users.sign_in')}
                label={<FontAwesomeIcon icon={faSignIn} />}
                onClick={() => handleSwitchUser(email)}
              />
            ) : null }
            */}
            <Link
              className="action"
              to={`/users/edit/${id}`}
              title={t('users.edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <Button
              type="button"
              className="action suppr"
              label={<FontAwesomeIcon icon={faTrashCan} />}
              title={t('users.suppr')}
              onClick={() => handleModal({ id })}
            />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Layout
        title={t('users.title')}
        queryError={
          getUsersListQuery.error
          || getUserMeQuery.error
          || deleteUserMutation.error
        }
        layout="table"
      >
        <header className="header">
          <div className="row">
            <h1 className="title">{t('users.title')}</h1>
            <Link className="add" to="/users/add">
              <FontAwesomeIcon icon={faPlus} />
              <span className="add_label">{t('users.add')}</span>
            </Link>
          </div>
          <div className="row">
            <Search className="mb-20" onChange={handleSearch} />
          </div>
        </header>
        <div className={styles.table}>
          <Table
            columns={columns}
            data={getUsersListQuery?.data?.data?.users || []}
            hasSort
            pagination={(
              <Pagination
                previousLabel={(
                  <FontAwesomeIcon icon={faChevronLeft} />
                )}
                nextLabel={(
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
                onPageChange={onPageChange}
                totalCount={getUsersListQuery?.data?.data?.total || 0}
                currentPage={page}
                pageSize={10}
                numberElementsDisplayed={getUsersListQuery?.data?.data?.users?.length || 0}
              />
            )}
          />
        </div>
      </Layout>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <p className="modal_paragraph">{t('global.modal_content')}</p>
        <div className="modal_actions">
          <Button
            type="button"
            className="modal_button"
            label={t('global.modal_dismiss')}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            className="modal_button modal_button--success"
            label={t('global.modal_validate')}
            onClick={handleDelete}
          />
        </div>
      </Modal>
    </>
  );
}

export default Users;
