import React from 'react';
import PropTypes from 'prop-types';

// components
import Card from '../../atoms/Card/Card';
import Picto from '../../atoms/Picto/Picto';

// styles
import styles from './CardDashboard.module.css';

// utils & misc
import cn from '../../../utils/cn';

function CardDashboard({
  title, picto, onClick, classNames, borderTopColored,
}) {
  return (
    <Card
      borderTopColored={borderTopColored}
      onClick={onClick}
      className={cn([styles.card, styles.cardSecurityTheme].concat(classNames))}
    >
      <div className={styles.leftColumn}>
        <p className={cn([styles.titleCard])}>
          { title }
        </p>
      </div>
      <div className={styles.rightColumn}>
        <Picto className={styles.picto} name={picto} />
      </div>
    </Card>
  );
}

CardDashboard.propTypes = {
  title: PropTypes.string.isRequired,
  picto: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  classNames: PropTypes.array.isRequired,
  borderTopColored: PropTypes.bool,
};

CardDashboard.defaultProps = {
  borderTopColored: false,
};

export default CardDashboard;
